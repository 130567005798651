import React, { Component } from 'react'
import queryString from 'query-string';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import * as CONSTANT from '../../utils/constant';
import { Link, withRouter } from 'react-router-dom'

export default class Shop extends Component {
    constructor(props) {
    window.scrollTo(0, 0);
        
        super(props)
        let param = props.location.search;
        const parsed = queryString.parse(param);
        let a, b, c, d, e;

        if (parsed.cat_id == undefined) {
            a = "0"
        } else {
            a = parsed.cat_id
        }
        if (parsed.subcat_id == undefined) {
            b = "0"
        } else {
            b = parsed.subcat_id
        }
        if (parsed.service_name == undefined) {
            c = "0"
        } else {
            c = parsed.service_name
        }
        this.state = {
            cat: [],
            subcat: [],
            cat_id: a,
            subcat_id: b,
            service_name: c,
            sort: 0,
            serviceDetails: [],
            filter_count: 0,
            serviceImage: []
        }
    }
    componentDidMount() {
        this.fetchApi()
        get(`/api/showCategory`)
            .then((response) => {
                this.setState({
                    cat: response.data.data.get_category
                })
            })
            .catch(function (error) {
                console.log(error);
            });
        get(`/api/showSubcategory`)
            .then((response) => {
                console.log(response.data.data.get_subcategory)
                this.setState({
                    subcat: response.data.data.get_subcategory
                })
            })
            .catch(function (error) {
                console.log(error);
            });

        get('/api/showServiceImage')
            .then((response) => {
                console.log(response.data)
                this.setState({
                    serviceImage: response.data.serviceimage
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    fetchApi() {
        const data = {
            cat_id: this.state.cat_id,
            subcat_id: this.state.subcat_id,
            service_name: this.state.service_name,
            status: this.state.sort,
        }

        post('/api/showShotingService', data)
            .then((response) => {
                console.log(response.data)
                this.setState({
                    serviceDetails: response.data.filter_details,
                    filter_count: response.data.total_filter
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div>

                <section className="shop-withsidebar-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                                <div className="shop-items">
                                    <div className="row showing-result-shorting">
                                        <div className="col-md-6">
                                            <div className="">
                                                <p>Showing {this.state.filter_count} results</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="">
                                                <select className="selectmenu">
                                                    <option selected="selected">Default Sorting</option>
                                                    <option value="1">High Price To Low Price</option>
                                                    <option value="2">Low Price To High Price</option>
                                                    <option value="3">Letest Service</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.serviceDetails.map((obj, i) =>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                                <div className="single-shop-item">
                                                    <div className="img-holder">
                                                        {this.state.serviceImage.filter(gs => gs.service_id == obj.s_id).map((object, i) =>
                                                            i == 0 ?
                                                                <>
                                                                    <img src={`${CONSTANT.URL}/storage/${object.service_icon}`} alt="Awesome Image" key={i} style={{ "height": "250px", "width": "100%" }} />
                                                                </> : null
                                                        )}
                                                        <div className="overlay">
                                                            <div className="box">
                                                                <div className="content">
                                                                    <button className="btn btn-info">Bookmark</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="title-holder">
                                                        <div className="top clearfix">
                                                            <div className="product-title pull-left">
                                                                <h5>{obj.service_name}</h5>
                                                            </div>
                                                            <div className="review-box pull-right">
                                                                <Link to={`projectdetails?id=${obj.service_id}`} className="btn btn-success">View Details</Link>
                                                            </div>
                                                        </div>
                                                        <h4>₹ {obj.service_price}</h4>
                                                    </div>
                                                </div>
                                            </div>

                                        )}

                                        {/*                                     
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="single-shop-item">
                                                <div className="img-holder">
                                                    <img src="images/shop/6.jpg" alt="Awesome Image" />
                                                    <div className="new-item">
                                                        <p>New</p>
                                                    </div>
                                                    <div className="overlay">
                                                        <div className="box">
                                                            <div className="content">
                                                                <a href="shop-single.html">Bookmark</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="top clearfix">
                                                        <div className="product-title pull-left">
                                                            <h5>Stackable Table</h5>
                                                        </div>
                                                        <div className="review-box pull-right">
                                                            <ul>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star-half-o"></i></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <h4>$20.00</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="single-shop-item">
                                                <div className="img-holder">
                                                    <img src="images/shop/7.jpg" alt="Awesome Image" />
                                                    <div className="overlay">
                                                        <div className="box">
                                                            <div className="content">
                                                                <a href="shop-single.html">Bookmark</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="top clearfix">
                                                        <div className="product-title pull-left">
                                                            <h5>Mountain Leaves</h5>
                                                        </div>
                                                        <div className="review-box pull-right">
                                                            <ul>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star-half-o"></i></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <h4>$19.99<del className="before-rate">$29.00</del></h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="single-shop-item">
                                                <div className="img-holder">
                                                    <img src="images/shop/9.jpg" alt="Awesome Image" />
                                                    <div className="overlay">
                                                        <div className="box">
                                                            <div className="content">
                                                                <a href="shop-single.html">Bookmark</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="top clearfix">
                                                        <div className="product-title pull-left">
                                                            <h5>Wireless Lamp</h5>
                                                        </div>
                                                        <div className="review-box pull-right">
                                                            <ul>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star-half-o"></i></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <h4>$19.99<del className="before-rate">$29.00</del></h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="single-shop-item">
                                                <div className="img-holder">
                                                    <img src="images/shop/10.jpg" alt="Awesome Image" />
                                                    <div className="new-item">
                                                        <p>New</p>
                                                    </div>
                                                    <div className="overlay">
                                                        <div className="box">
                                                            <div className="content">
                                                                <a href="shop-single.html">Bookmark</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="top clearfix">
                                                        <div className="product-title pull-left">
                                                            <h5>Working Chair</h5>
                                                        </div>
                                                        <div className="review-box pull-right">
                                                            <ul>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star-half-o"></i></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <h4>$24.00</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="single-shop-item">
                                                <div className="img-holder">
                                                    <img src="images/shop/11.jpg" alt="Awesome Image" />
                                                    <div className="overlay">
                                                        <div className="box">
                                                            <div className="content">
                                                                <a href="shop-single.html">Bookmark</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="top clearfix">
                                                        <div className="product-title pull-left">
                                                            <h5>Gardern Lamp</h5>
                                                        </div>
                                                        <div className="review-box pull-right">
                                                            <ul>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star-half-o"></i></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <h4>$19.99<del className="before-rate">$29.00</del></h4>
                                                </div>
                                            </div>
                                        </div>
                                  */}

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-7 col-xs-12">
                                <div className="sidebar-wrapper">



                                    <div className="single-sidebar">
                                        <div className="sidebar-title">
                                            <h1>Categories</h1>
                                        </div>
                                        <ul className="categories clearfix">
                                            {this.state.cat.map((obj, i) =>
                                                <li>
                                                    <a href="javascript:void(0)"><i className="fa fa-angle-right" aria-hidden="true"></i> {obj.cat_name}</a>
                                                </li>
                                            )}

                                        </ul>
                                    </div>

                                    <div className="single-sidebar">
                                        <div className="sidebar-title">
                                            <h1>Sub Categories</h1>
                                        </div>
                                        <ul className="categories clearfix">
                                            {this.state.subcat.map((obj, i) =>

                                                <li>
                                                    <a href="javascript:void(0)"><i className="fa fa-angle-right" aria-hidden="true"></i> {obj.subcat_name}</a>
                                                </li>
                                            )}

                                        </ul>
                                    </div>


                                    <div className="single-sidebar">
                                        <div className="sidebar-title">
                                            <h1>Recent Products</h1>
                                        </div>
                                        <ul className="recent-products">
                                            <li>
                                                <div className="img-holder">
                                                    <img src="images/shop/product-thumb-1.jpg" alt="Awesome Image" />
                                                    <div className="overlay">
                                                        <div className="box">
                                                            <div className="content">
                                                                <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title">
                                                    <h5><a href="#">Wooden Chair</a></h5>
                                                    <p>$34.99</p>
                                                    <div className="review">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star-half-o"></i>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="img-holder">
                                                    <img src="images/shop/product-thumb-2.jpg" alt="Awesome Image" />
                                                    <div className="overlay">
                                                        <div className="box">
                                                            <div className="content">
                                                                <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title">
                                                    <h5><a href="#">Bridcadge Tree</a></h5>
                                                    <p>$24.00</p>
                                                    <div className="review">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star-half-o"></i>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="img-holder">
                                                    <img src="images/shop/product-thumb-3.jpg" alt="Awesome Image" />
                                                    <div className="overlay">
                                                        <div className="box">
                                                            <div className="content">
                                                                <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title">
                                                    <h5><a href="#">Stackable Table</a></h5>
                                                    <p>$20.00</p>
                                                    <div className="review">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star-half-o"></i>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
