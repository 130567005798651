import React, { Component } from 'react'

export default class FooterButton extends Component {
    render() {
        return (
            <div>
                <section className="footer-bottom-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                <div className="copyright-text">
                                    <p>© 2016 Interrio, All Rights Reserved by <a href="#">Steelthemes</a></p>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                <div className="footer-menu">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-rss" aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-skype" aria-hidden="true"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
