import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator';
import { imageValid } from '../../utils/imageValid';
import * as CONSTANT from '../../utils/constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import BackDrop1 from '../Loader/BackDrop1';

export default class Profile extends Component {
    constructor(props) {
        super(props)
          window.scrollTo(0, 0);

        this.state = {
            name: '',
            address: '',
            email: '',
            phone: '',
            profileImg: '',
            city: '',
            state: '',
            pin_code: '',
            extra_phone_no: '',
            current_password: '',
            password: '',
            confirm_password: '',
            loading:false,
        }
        this.validator = new SimpleReactValidator({
            messages: {
                in: 'Passwords need to match!'
            }
        })
    }
    
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    componentDidMount() {

        authget(`/api/ShowUserDetails`)
            .then((response) => {
                if (response.data.status === true) {
                    this.setState({
                        name: response.data.user.name,
                        address: response.data.user.address,
                        email: response.data.user.email,
                        phone: response.data.user.phone,
                        city: response.data.user_details.city,
                        state: response.data.user_details.state,
                        profileImg: `${CONSTANT.URL}/storage/${response.data.user_details.profile_image}`,
                        pin_code: response.data.user_details.pin_code,
                        extra_phone_no: response.data.user_details.extra_phone_no,
                    })

                } else {
                    this.setState({
                        name: response.data.user.name,
                        address: response.data.user.address,
                        email: response.data.user.email,
                        phone: response.data.user.phone,
                    })
                }


            })
            .catch(function (error) {
                console.log(error);
            });

    }
    imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                this.setState({ profileImg: reader.result })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    };

    validateImage = (e) => {
        var formData = new FormData();

        var id = e.target.id
        var file = document.getElementById(id).files[0];
        // console.log(id);
        formData.append("Filedata", file);
        var t = file.type.split("/").pop().toLowerCase();
        if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
            //   alert('Please select a valid image file');
            const id = toast.error('Please select a valid image file!');

            e.target.value = "";
            return false;
        }
        if (file.size > 2048000) {
            // alert('Max Upload size is 1MB only');
            const id = toast.error('Max Upload size is 2MB only!');

            e.target.value = "";
            return false;
        }
        this.imageHandler(e);
        return true;
    }
    handleSubmit1 = (e) => {
        e.preventDefault();
        this.setState({loading:true})
        var formdata = new FormData(e.target);
        var formdata1 = [...formdata];
        authformpost('/api/profileUpdate', formdata)
            .then((data) => {
                if(data.data.success === true){
                 this.setState({
                    profileImg: `${CONSTANT.URL}/storage/${data.data.user_details.profile_image}`,
                 })
                 toast.dark(data.data.msg,
                 { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
            
                }else{
                    toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                }
                  this.setState({loading:false})

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    handleSubmit=(e)=>{
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
       
            authformpost('/api/userDetailsUpdate',formdata)
                .then((data) => {
                    if(data.data.success === true){
                        this.setState({
                            name: data.data.user.name,
                            address: data.data.user.address,
                            email: data.data.user.email,
                            phone: data.data.user.phone,
                            city: data.data.user_details.city,
                            state: data.data.user_details.state,
                            // profileImg: `${CONSTANT.URL}/storage/${data.data.user_details.profile_image}`,
                            pin_code: data.data.user_details.pin_code,
                            extra_phone_no: data.data.user_details.extra_phone_no,
                        })
                        toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                        this.setState({loading:false})
                        
                    }else{
                        toast.dark(data.data.msg,
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                          this.setState({loading:false})
                    }

                    // console.log(data.data)
                    // toast.dark('Register Successful',
                    //     { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    // this.props.history.push('/login');
                    // this.setState({ loading: false });


                }).catch((err) => {
                    this.setState({ loading: false });
                })

    }
    handleSubmit2=(e)=>{
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        if (this.validator.allValid()) {
            authformpost('/api/change_password',formdata)
                .then((data) => {
                    // console.log(data.data.error.current_password[0])
                    if(data.data.status === true){
                        toast.dark('Password Change Successfully ',
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                   
                    }else{
                        toast.dark(data.data.error.current_password[0],
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    }
                  this.setState({loading:false})

                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }
    render() {
        const { profileImg } = this.state;
        return (
            <div>
                {this.state.loading?
                <BackDrop1/>:null}
                <section className="breadcrumb-area" style={{ "background-image": "url(images/breadcrumb/breadcrumb-bg.jpg)" }}>
                    <div className="container-fluid text-center">
                        <h1>Profile</h1>
                        <div className="breadcrumb-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="left pull-left">
                                            <ul>
                                                <li><Link to="/">Home</Link></li>
                                                <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>

                                                <li>Profile</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="account-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div className="form login-form">
                                    <div className="sec-title-two">
                                        <h3>Profile Image Update</h3>
                                    </div>

                                    <div style={{ backgroundColor: "rgb(199,196,196)" }}>
                                        <center>
                                            {this.state.profileImg === '' ?
                                                <img src='https://cdn.iconscout.com/icon/free/png-256/user-1648810-1401302.png' style={{ width: "50%", "border-radius": "100%", "margin-top": "10px", "margin-bottom": "10px" }} className="avatar-img rounded-circle" />
                                                :
                                                <img src={profileImg} style={{ width: "50%", "border-radius": "100%", "margin-top": "10px", "margin-bottom": "10px" }} className="avatar-img rounded-circle" />
                                            }
                                        </center>
                                    </div>
                                    <form onSubmit={this.handleSubmit1} encType="multipart/form-data">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="field-label">Select Profile Image</div>
                                                <div className="field-input">
                                                    <input type="File" name="profile_image" id="profile_image" className="form-control" placeholder="Profile_image" onChange={this.validateImage} style={{ "height": "43px" }} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <button className="btn btn-success" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="form register">
                                    <div className="sec-title-two">
                                        <h3>Add/Update Your Details</h3>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="field-label">Name</div>
                                                <div className="field-input">
                                                    <input type="text" name="name" placeholder="Enter Your Name" value={this.state.name}  onChange={this.handleChange} required/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="field-label">Email Address</div>
                                                <div className="field-input">
                                                    <input type="text" name="email" id="email" placeholder="Email Id" value={this.state.email} readOnly/>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="field-label">Phone Number</div>
                                                <div className="field-input">
                                                    <input type="number" className="form-control" name="phone" placeholder="Enter Your Phone Number" value={this.state.phone} style={{ "height": "43px" }} readOnly required/>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="field-label">Address</div>
                                                <div className="field-input">
                                                    <input type="text" name="address" placeholder="Enter Your Address" value={this.state.address}  onChange={this.handleChange} required/>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="field-label">Enter Your City</div>
                                                <div className="field-input">
                                                    <input type="text" name="city" placeholder="Enter Your City" value={this.state.city}  onChange={this.handleChange} required/>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="field-label">State</div>
                                                <div className="field-input">
                                                    <input type="text" name="state" placeholder=" State" value={this.state.state}  onChange={this.handleChange} required/>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="field-label">Pin Code</div>
                                                <div className="field-input">
                                                    <input type="number" className="form-control" name="pin_code" placeholder="Pin Code" value={this.state.pin_code}  onChange={this.handleChange} style={{ "height": "43px" }} required/>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="field-label">Extra Phone Number (Optional)</div>
                                                <div className="field-input">
                                                    <input type="number" className="form-control" name="extra_phone_no" value={this.state.extra_phone_no} placeholder="Extra Phone no"  onChange={this.handleChange} style={{ "height": "43px" }} />

                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <button className="thm-btn bg-cl-1" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div className="form login-form">
                                    <div className="sec-title-two">
                                        <h3>Change Password</h3>
                                    </div>
                                    <form onSubmit={this.handleSubmit2}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="field-label">Enter Current Password</div>
                                                <div className="field-input">
                                                    <input type="password" name="current_password" className="form-control" value={this.state.current_password}  onChange={this.handleChange} placeholder="Current Password" style={{ "height": "43px" }} />
                                                    {this.validator.message('current_password', this.state.current_password, 'required', { className: 'text-danger' })}

                                                </div>
                                                <div className="field-label">New Password</div>
                                                <div className="field-input">
                                                    <input type="password" name="password" className="form-control" placeholder="Password" value={this.state.password}  onChange={this.handleChange} style={{ "height": "43px" }} />
                                                    {this.validator.message('password', this.state.password, 'required|min:8', { className: 'text-danger' })}

                                                </div>
                                                <div className="field-label">Confirm Password</div>

                                                <div className="field-input">
                                                    <input type="password" className="form-control" name="confirm_password" value={this.state.confirm_password}  onChange={this.handleChange} className="form-control" placeholder="Confirm Password" style={{ "height": "43px" }} />
                                                    {this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`,{ className: 'text-danger' }, {messages: {in: 'Passwords need to match!'}})}
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <button className="btn btn-success" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
