import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackDrop1 from '../Loader/BackDrop1';
toast.configure();

export default class ForgetPassword extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0);
    
        this.state = {
            phone:'' ,
            otp:'',
            otp1:'',
            password:'',
            sendotp:'Send OTP',
            loading:false,
        }
        this.validator = new SimpleReactValidator();
        this.buttonotp = React.createRef()
        this.buttonrechange = React.createRef()
        this.otpinput = React.createRef()
        this.confirminput = React.createRef()

    }
    otpSubmit =(e)=>{
        e.preventDefault();
        this.setState({ loading: true });

        var formdata = {
            phone: this.state.phone,
        };

      
            post('/api/phone_no_check_in_forget', formdata)
                .then((data) => {
                
                    if(data.data.success == true){
                    this.setState({
                      otp1:data.data.otp,
                      sendotp:'RESEND OTP',
                      msg:''

                    })
                    var x = this.otpinput.current;
                    x.classList.remove('hide');
                    this.setState({ loading: false });
                  }else{
                    this.setState({
                       
                        msg:data.data.msg,
                        // sendotp:'RESEND OTP'
                      })
                      this.setState({ loading: false });
                  }
                }).catch((err) => {
                    this.setState({ loading: false });
                })
     
    }


    forgetSubmit =(e)=>{
        e.preventDefault();
        this.setState({ loading: true });

        var formdata = {
            phone: this.state.phone,
            password:this.state.password,
        };

        if (this.validator.allValid()) {
            post('/api/forgot_password', formdata)
                .then((data) => {
                  if(data.data.status == 1){
                    toast.dark(data.data.msg,
                    { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    this.props.history.push('/login');
                    }else{
                        toast.dark(data.data.msg,
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })        
                    }

                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }


   

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
        if(e.target.name == 'otp'){
            var y = this.buttonrechange.current; 
            var x = this.confirminput.current;
            var z=this.buttonotp.current;
            if(e.target.value.toString().length>=6){
               
               
                if(Number(e.target.value) == Number(this.state.otp1))
                {
                    z.classList.add('hide');
                    x.classList.add('show');
                    y.classList.add('show');
                    this.setState({
                        msg:''
                   
                    })
                }else{
                   
                    y.classList.remove('show');
                    x.classList.remove('show');
                    this.setState({
                        msg:'OTP Did Not Match'
                    })
                }
            }else{
                y.classList.remove('show');
                x.classList.remove('show');
            }
        }
    }
    
    render() {
      
        return (
            <div>
                {this.state.loading?<BackDrop1/>:null}
                <section className="account-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="form login-form">
                                    <div className="sec-title-two">
                                        <h3>Forget Password</h3>
                                    </div>
                                    <form >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="field-label">Phone Number</div>
                                                <div className="field-input">
                                                    <input type="number" class="form-control" name="phone" placeholder="Enter Phone Number" style={{ "height": "43px" }} onChange={this.handleChange} value={this.state.phone} required/>
                                                    {this.validator.message('phone', this.state.phone, 'required', { className: 'text-danger' })}
                                                   
                                                </div>
                                            </div>

                                      

                                            <div className="col-md-12 hide" ref={this.otpinput}>
                                                <div className="field-label">Enter OTP</div>
                                                <div className="field-input">
                                                    <input type="number" class="form-control" name="otp" placeholder="Enter OTP" style={{ "height": "43px" }} onChange={this.handleChange} value={this.state.otp}/>
                                                </div>
                                            </div>
                                          
                                            <div className="col-md-12 hide" ref={this.confirminput}>
                                                <div className="field-label">Enter New Password</div>
                                                <div className="field-input">
                                                    <input className="form-control" type="password" name="password" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" style={{ "height": "43px" }} onChange={this.handleChange} value={this.state.password} />
                                                    {this.validator.message('password', this.state.password, 'required|min:8|max:120', { className: 'text-danger' })}
                                                   
                                                </div>
                                            </div>

                                         
                                            <div style={{color:"red"}}>{this.state.msg}</div>
                                            
                                            <div className="col-md-12 col-sm-12 col-xs-12" ref={this.buttonotp}>
                                                <button className="thm-btn bg-cl-1" type="submit" onClick={this.otpSubmit}>{this.state.sendotp}</button>
                                            </div>
                                          
                                            <div className="col-md-12 col-sm-12 col-xs-12 hide" ref={this.buttonrechange}>
                                                <button className="thm-btn bg-cl-1" type="submit" onClick={this.forgetSubmit}>Submit</button>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                               
                                               <div className="forgot-password pull-right">
                                                   <Link to="/login">Login?</Link>
                                               </div>
                                           </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
