import {
    Add_To_Cart,
    Remove_To_Cart,
    Update_Profile,
    SHOW_CART,
} from '../constants'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { authget, get, post, authpost, authformpost } from '../../utils/service'
import * as CONSTANT from '../../utils/constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// export const removeToCart=(data)=>{
//     return {
//         type:Remove_To_Cart,
//         data:data
//     }

// }
export const addToCart = (data) => {
    const a = {
        user_id: localStorage.getItem('user_id'),
        service_id: data,
    }
    console.log(a)
    return (dispatch) => {
        authpost("/api/addToCart", a)
            .then((response) => {
                console.log(response)
                if (response.data.success === true) {
                    const data = {
                        cart_count: response.data.cartcount,
                        cartdata: response.data.cartdata,
                    }
                    dispatch({ type: SHOW_CART, payload: data })
                    toast.dark("Service Add to cart",
                    { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                } else {
                    toast.dark("service Already Have Your cart",
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export const showCart = () => {
    return (dispatch) => {
        var user_id = localStorage.getItem('user_id');
        authget(`/api/showCart?user_id=${user_id}`)
            .then((response) => {
                console.log(response)
                if (response.data.success === true) {
                    const data = {
                        cart_count: response.data.cartcount,
                        cartdata: response.data.data,
                    }
                    dispatch({ type: Add_To_Cart, payload: data })
                } else {
                    // toast.dark("service Already Have Your cart",
                    //     { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

