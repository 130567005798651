import React, { useState, useEffect } from "react";
import {
  authput,
  authget,
  post,
  authformpost,
  authdelete,
  get,
  authpost,
} from "../../utils/service";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { checkAuth } from "../../utils/auth";

import { Link, withRouter } from "react-router-dom";
import * as CONSTANT from "../../utils/constant";
import { Modal } from "react-responsive-modal";

function Plumber() {
  window.scrollTo(0, 0);
  const [open, setopen] = useState(false);
  const [warkersId, setWorkersId] = useState("");
  const [loading, setLoading] = useState(false);
  const [ratting, setRatting] = useState("");
  const [comment, setComment] = useState("");
  const [totaldata, settotaldata] = useState(0);
  const [plumber, setPlumber] = useState([]);
  const [city, setCity] = useState([]);
  const [category, setcategory] = useState([]);

  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [currantPage, setCurrentPage] = useState(0);

  const [reviewRatin, serreviewRating] = useState(0);
  const [details, setDetails] = useState("");

  const [city_id, setCity_id] = useState("");

  const [s_category_id, setS_category_id] = useState("");

  const [name, setName] = useState("");

  useEffect(() => {
    // apipcall(10, currantPage);
    apicitycall();
    apiViewSpCatusercall();
  }, []);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    apipcall(10, selected + 1, city_id, name, s_category_id);
  };

  const apipcall = (perpage, page, city_id, name, s_category_id) => {
    // console.log("..................", city_id);
    let data = {
      perpage,
      page,
      city: city_id,
      worker_name: name,
      s_category_id: s_category_id,
    };

    post(`/api/viewWorkerswithpaginatation`, data)
      .then((response) => {
        // console.log("gs", response.data);
        setPlumber(response.data.data);
        setTotalPage(response.data.total_Page);
        // setCurrentPage(response.data.data.currentPage);
        setPage(response.data.page);
        settotaldata(response.data.totaldata);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const apicitycall = () => {
    get(`/api/viewCity`)
      .then((response) => {
        // console.log(response.data);
        setCity(response.data.city.filter((gs) => gs?.city_status == 1));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const apiViewSpCatusercall = () => {
    get(`/api/ViewSpCatuser`)
      .then((response) => {
        // console.log(response.data);
        setcategory(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    // apipcall(10, currantPage, e.target.value, name);
    setCity_id(e.target.value);
  };

  const handleChange1 = (e) => {
    // apipcall(10, currantPage, e.target.value, name);
    setS_category_id(e.target.value);
    // setCity_id(e.target.value);
  };

  const handleNameChange = (e) => {
    // console.log(e.target.value);
    setName(e.target.value);
  };

  const bb = () => {
    apipcall(10, currantPage, city_id, name, s_category_id);
  };

  const onCloseModal = () => {
    setopen(false);
  };
  const onOpenModal = (id) => {
    const data = {
      worker_id: id,
    };

    authpost(`/api/ViewRatingUser`, data)
      .then((response) => {
        console.log(response?.status == 200);
        if (response?.status == 200) {
          serreviewRating(response?.data?.data?.rating);
          setDetails(response?.data?.data?.details);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setopen(true);
    setWorkersId(id);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    var formdata = new FormData(e.target);
    const data = {
      // formData?.get("id")
      worker_id: warkersId,
      rating: reviewRatin,
      details: details,
    };
    // console.log(data);

    authpost("/api/AddRating", data)
      .then((data) => {
        apipcall(10, currantPage, city_id, name, s_category_id);

        if (data.data.status == true) {
          // bb();

          toast.dark(data.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.dark(data.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        }
        setLoading(false);
        setopen(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };

  const onOpenModal1 = () => {
    toast.dark("Please Login", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 3000,
    });
  };

  return (
    <>
      <Modal open={open} onClose={onCloseModal} center>
        <h4 style={{ paddingLeft: "10px" }}>Submit Your Review </h4>
        <hr />
        <div className="card wid">
          <div className="card-body pd">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label for="exampleInputEmail1">Rating</label>
                <br />

                <div class="rate">
                  <input
                    type="radio"
                    id="star5"
                    name="reting"
                    value="5"
                    required
                    checked={reviewRatin == 5}
                    onClick={() => {
                      serreviewRating(5);
                    }}
                  />
                  <label for="star5" title="text">
                    5 stars
                  </label>
                  <input
                    type="radio"
                    id="star4"
                    name="reting"
                    value="4"
                    required
                    checked={reviewRatin == 4}
                    onClick={() => {
                      serreviewRating(4);
                    }}
                  />
                  <label for="star4" title="text">
                    4 stars
                  </label>
                  <input
                    type="radio"
                    id="star3"
                    name="reting"
                    value="3"
                    required
                    checked={reviewRatin == 3}
                    onClick={() => {
                      serreviewRating(3);
                    }}
                  />
                  <label for="star3" title="text">
                    3 stars
                  </label>
                  <input
                    type="radio"
                    id="star2"
                    name="reting"
                    value="2"
                    required
                    checked={reviewRatin == 2}
                    onClick={() => {
                      serreviewRating(2);
                    }}
                  />
                  <label for="star2" title="text">
                    2 stars
                  </label>
                  <input
                    type="radio"
                    id="star1"
                    name="reting"
                    value="1"
                    required
                    checked={reviewRatin == 1}
                    onClick={() => {
                      serreviewRating(1);
                    }}
                  />
                  <label for="star1" title="text">
                    1 star
                  </label>
                </div>
                <br />
              </div>

              <div className="form-group">
                <p style={{ margin: "20px 0 10px" }}>
                  <b>Comment</b>{" "}
                </p>
                <textarea
                  className="form-control"
                  name="comment"
                  id="comment"
                  value={details}
                  placeholder="Comment"
                  onChange={(e) => {
                    setDetails(e.target.value);
                  }}
                  required
                ></textarea>
              </div>

              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </Modal>

      <section className="shop-withsidebar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="shop-items">
                <div className="row showing-result-shorting">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="showing pull-left">
                      <p>Showing {totaldata} results</p>
                    </div>
                    <div className="shorting pull-right">
                      <div className="row"></div>
                    </div>
                  </div>
                  <div className="abc11">
                    <div
                      className="col-lg-5 col-md-5 col-sm-5 col-xs-12 col-12"
                      style={{ paddingBottom: "30px" }}
                    >
                      <select
                        // className="selectmenu"
                        name="w_city_id"
                        id="w_city_id"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option value="" selected disabled>
                          Select City{" "}
                        </option>
                        {/* {console.log(city)} */}
                        {city.map((object, i) => (
                          <option value={object?.id}>
                            {object?.city_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="col-lg-5 col-md-5 col-sm-5 col-xs-12 col-12"
                      style={{ paddingBottom: "30px" }}
                    >
                      <select
                        // className="selectmenu"
                        name="s_category_id"
                        id="s_category_id"
                        className="form-control"
                        onChange={handleChange1}
                      >
                        <option value="" selected disabled>
                          Select category{" "}
                        </option>
                        {/* {console.log(city)} */}
                        {category.map((object, i) => (
                          <option value={object?.id}>
                            {object?.s_category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="col-lg-2 col-md-2 col-sm-2 col-xs-12 col-12"
                      style={{ paddingBottom: "30px" }}
                    >
                      <button
                        className="btn btn-success form-control"
                        onClick={bb}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  {/* <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style={{ paddingRight: "30px" }}
                  >
                    <div className="sidebar-wrapper">
                      <div className="single-sidebar">
                        <form className="search-form" action="#">
                          <input
                            placeholder="Search..."
                            type="text"
                            name="search"
                            id="search"
                            onChange={handleNameChange}
                          />

                          <button type="button" onClick={bb}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  {plumber?.length > 0 ? (
                    plumber.map((obj, i) => (
                      <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="single-blog-item">
                          <div class="img-holder">
                            {/* <img src="images/blog/lat-blog-1.jpg" alt="Awesome Image" /> */}
                            <div class="overlay">
                              <div class="box">
                                <div class="content">
                                  <a>
                                    <i
                                      class="fa fa-link"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-holder">
                            <a>
                              <h3 class="blog-title">{obj?.w_name}</h3>
                            </a>
                            <ul class="meta-info">
                              <li>
                                City : &nbsp;
                                <a>{obj.city_name}</a>
                              </li>
                              <li>
                                Category : &nbsp;
                                <a>{obj.s_category_name}</a>
                              </li>
                              <li>
                                Phone no : &nbsp;
                                <a>{obj.w_phone_no}</a>
                              </li>
                              <li>
                                Whatapp no : &nbsp;
                                <a>{obj.w_whatapp_no}</a>
                              </li>
                              <li>
                                Rating : &nbsp;
                                <span
                                  class="badge bg-success"
                                  style={
                                    {
                                      // fontSize: "10px",
                                      // backgroundColor: "rgb(37,211,102)",
                                    }
                                  }
                                >
                                  <i
                                    className="fas fa-star"
                                    style={{
                                      fontSize: "10px",
                                      // color: "rgb(37,211,102)",
                                    }}
                                  ></i>{" "}
                                  {obj.w_reating}
                                </span>
                                ({obj.w_total_review})
                              </li>
                            </ul>
                            <div class="text">
                              {/* <p>{obj?.w_address}</p> */}
                              {checkAuth.isAuthenticated ? (
                                <a
                                  class="readmore cp"
                                  onClick={() => onOpenModal(obj?.id)}
                                >
                                  Add Rating
                                  <i
                                    class="fa fa-caret-right"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              ) : (
                                <a
                                  class="readmore cp"
                                  onClick={() => onOpenModal1()}
                                >
                                  Add Rating
                                  <i
                                    class="fa fa-caret-right"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <center>
                        <h3>Data Not Found!</h3>
                      </center>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      pageCount={totalPage}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                      previousLabel="Previous"
                      renderOnZeroPageCount={null}
                      forcePage={currantPage - 1}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-4 col-sm-7 col-xs-12">
              <div className="sidebar-wrapper">
                <div className="single-sidebar">
                  <form className="search-form" action="#">
                    <input placeholder="Search..." type="text" />
                    <button type="submit">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Plumber;
