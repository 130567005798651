import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator';
import * as CONSTANT from '../../utils/constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import BackDrop1 from '../Loader/BackDrop1';

export default class Partner extends Component {
    constructor(props) {
        super(props)
    window.scrollTo(0, 0);

        this.state = {
            name: '',
            email: '',
            phone: '',
            description: '',
            loading: false,
        }
        this.validator = new SimpleReactValidator({
            messages: {
                in: 'Passwords need to match!'
            }
        })
    }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        var formdata = new FormData(e.target);
        if (this.validator.allValid()) {
            authformpost('/api/addpartner', formdata)
                .then((data) => {
                    // console.log(data.data)

                    if (data.data.status === true) {
                        toast.dark(data.data.msg,
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

                    } else {
                        toast.dark(data.data.msg,
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    }
                    this.setState({ loading: false })

                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <div>
                {this.state.loading ?
                    <BackDrop1 /> : null
                }


                <section id="blog-area" className="blog-single-area ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                <div className="blog-post">
                                    <div className="single-blog-item">
                                        <div className="text-holder">
                                            <div className="sec-title-two">
                                                <h3>BECOME A PARTNER </h3>
                                            </div>
                                            {/* <ul className="meta-info">
                                                <li><i className="fa fa-user" aria-hidden="true"></i><a href="#">Fletcher</a></li>
                                                <li><i className="fa fa-clock-o" aria-hidden="true"></i><a href="#">April 21, 2016</a></li>
                                                <li><i className="fa fa-folder-open-o" aria-hidden="true"></i><a href="#">Residential</a></li>
                                                <li><i className="fa fa-comments" aria-hidden="true"></i><a href="#">14</a></li>
                                            </ul> */}
                                            <div className="text">
                                                <p className="mar-bottom">
                                                We are looking forward to an extremely enthusiastic and hard-working "Freelancer" and a "Building Consultant".
                                                </p>
                                                <p>
                                                By becoming a freelancer with us, you could have the flexibility of working hours; get a grip on new skills, quality work, freedom to work from anywhere, work-life balance, and new connections. Stand a chance to experience an all-in-one opportunity with us. Enhance your skills by prepping up to this amazing experience.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="middle-content-box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="img-holder">
                                                    <img src="images/blog/blog-single-1.jpg" alt="Awesome Image" />
                                                </div>
                                                <div className="text-holder">
                                                    <p>As a "Building Consultant" you could work on a variety of projects with us if you have a key insight on relative topics. By doing so, you could expand your connections, acquire new skills and also grow with us.</p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bottom-content-box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="text-holder">
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-7 col-xs-12">
                                <div className="form register bgc">
                                    <div className="sec-title-two">
                                        <h3>Become a Partner </h3>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="field-label black">Name</div>
                                                <div className="field-input">
                                                    <input type="text" className="form-control" name="name" placeholder="Enter Your Name" value={this.state.name} onChange={this.handleChange} style={{ "height": "43px" }} />
                                                    {this.validator.message('name', this.state.name, 'required', { className: 'text-danger' })}

                                                </div>
                                            </div>
                                            <div className="col-md-12"><br />
                                                <div className="field-label black">Phone No</div>
                                                <div className="field-input">
                                                    <input type="number" className="form-control" name="phone" value={this.state.phone} onChange={this.handleChange} placeholder="Enter Phone Number" style={{ "height": "43px" }} />
                                                    {this.validator.message('phone', this.state.phone, 'required', { className: 'text-danger' })}

                                                </div>
                                            </div>
                                            <div className="col-md-12"><br />
                                                <div className="field-label black">Email Address (Optional)</div>
                                                <div className="field-input">

                                                    <input type="email" name="email" className="form-control" placeholder="Enter Email" value={this.state.email} onChange={this.handleChange} style={{ "height": "43px" }} />
                                                    {/* {this.validator.message('email', this.state.name, 'required', { className: 'text-danger' })} */}

                                                </div>
                                            </div>
                                            <div className="col-md-12"><br />
                                                <div className="field-label black">Description (Optional) </div>
                                                <div className="field-input">
                                                    <textarea className="form-control" name="description" value={this.state.description} onChange={this.handleChange}></textarea>
                                                    {/* {this.validator.message('description', this.state.description, 'required', { className: 'text-danger' })} */}

                                                </div>
                                            </div>

                                            <div className="col-md-12 col-sm-12 col-xs-12"><br/>
                                                <button className="thm-btn bg-cl-1" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>



            </div>
        )
    }
}
