import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, withRouter } from 'react-router-dom'
import * as CONSTANT from '../../utils/constant';
import { authput, authget, post, authformpost, authdelete } from '../../utils/service'
var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ display: "block", position: "absolute", right: "23px", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}
            onClick={onClick}
        >
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick} style={{ display: "block", position: "absolute", left: "23px", zIndex: "10", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}>
        </div>
    );
}

export default class ServiceArea extends Component {
    constructor(props) {
        super(props)

        this.state = {
            competeService: [],
        }
    }


    componentDidMount() {

        authget(`/api/viewCompleteService`)
            .then((response) => {
                const cs = response.data.completeService.filter(gs => gs.complete_service_status == 1)
                this.setState({
                    competeService: cs
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true,
                        autoplay: true,
                        speed: 500,
                        autoplaySpeed: 3000,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        autoplay: true,
                        speed: 500,
                        autoplaySpeed: 3000,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                        speed: 500,
                        autoplaySpeed: 3000,
                    }
                }
            ]
        };
        return (
            <div>

                <section className="service-area">
                    <div className="container">
                        <div className="sec-title">
                            <h2>Completed Project</h2>
                            <span className="decor"></span>
                        </div>

                        <Slider {...settings}>

                            {this.state.competeService.length != 0 ?
                                this.state.competeService.map((object, i) =>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                        <div className="single-service-item">
                                            <div className="img-holder">
                                                {object.get_complete_serviceimage.map((object, i) =>
                                                    i === 0 ?
                                                        <img src={`${CONSTANT.URL}/storage/${object.complete_service_icon}`} alt="Awesome Image" key={i} />
                                                        : null
                                                )}

                                                <div className="overlay">
                                                    <div className="box">
                                                        <div className="content">
                                                            <Link to={`/completeprojectdetails?id=${urlCrypt.cryptObj(object.get_complete_serviceimage)}`}><i className="fa fa-link" aria-hidden="true"></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <a href="javascript:void(0);">
                                                    <h3><span>{object.complete_service_name}</span></h3>
                                                </a>
                                                <p>{object.complete_service_des}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}


                        </Slider>





                        {/* <div className="row">
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-service-item">
                                    <div className="img-holder">
                                        <img src="images/services/1.jpg" alt="Awesome Image" />
                                        <div className="overlay">
                                            <div className="box">
                                                <div className="content">
                                                    <a href="services-single.html"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <a href="services-single.html">
                                            <h3>Residential <span>Design</span></h3>
                                        </a>
                                        <p>We design Wardrobes, Kitchen Cabinets based...</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-service-item">
                                    <div className="img-holder">
                                        <img src="images/services/2.jpg" alt="Awesome Image" />
                                        <div className="overlay">
                                            <div className="box">
                                                <div className="content">
                                                    <a href="services-single.html"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <a href="services-single.html">
                                            <h3>Commercial <span>Design</span></h3>
                                        </a>
                                        <p>Best projects and products in the commercial...</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-service-item">
                                    <div className="img-holder">
                                        <img src="images/services/3.jpg" alt="Awesome Image" />
                                        <div className="overlay">
                                            <div className="box">
                                                <div className="content">
                                                    <a href="services-single.html"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <a href="services-single.html">
                                            <h3>Corporate <span>Design</span></h3>
                                        </a>
                                        <p>Changing business climate, workplace design...</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-service-item">
                                    <div className="img-holder">
                                        <img src="images/services/4.jpg" alt="Awesome Image" />
                                        <div className="overlay">
                                            <div className="box">
                                                <div className="content">
                                                    <a href="services-single.html"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <a href="services-single.html">
                                            <h3>Hospitality <span>Design</span></h3>
                                        </a>
                                        <p>Designing hospital area with equipments needs...</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-service-item">
                                    <div className="img-holder">
                                        <img src="images/services/5.jpg" alt="Awesome Image" />
                                        <div className="overlay">
                                            <div className="box">
                                                <div className="content">
                                                    <a href="services-single.html"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <a href="services-single.html">
                                            <h3>Restaurant <span>Design</span></h3>
                                        </a>
                                        <p>Will differentiate from the ordinary restaurants...</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-service-item">
                                    <div className="img-holder">
                                        <img src="images/services/6.jpg" alt="Awesome Image" />
                                        <div className="overlay">
                                            <div className="box">
                                                <div className="content">
                                                    <a href="services-single.html"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <a href="services-single.html">
                                            <h3>Industrial <span>Design</span></h3>
                                        </a>
                                        <p>Will differentiate you from the ordinary restaurants</p>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </section>



            </div>
        )
    }
}
