import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, withRouter } from 'react-router-dom'
import * as CONSTANT from '../../utils/constant';
import CopyText from 'react-copy-text'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import GreadLoader from '../Loader/GreadLoader';
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ display: "block", position: "absolute", right: "23px", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}
            onClick={onClick}
        >
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick} style={{ display: "block", position: "absolute", left: "23px", zIndex: "10", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}>
        </div>
    );
}


export default class Feedback extends Component {
    constructor(props) {
        super(props)

        this.state = {
            review: [],
            adminDetails: [],
        }
    }
    componentDidMount() {
        get('/api/showreview')
            .then((data) => {
                console.log(data.data.showreview)
                console.log(data.data.adminDetails)
                this.setState({
                    review: data.data.showreview,
                    adminDetails: data.data.adminDetails,
                })
            }).catch((err) => {
                this.setState({ loading: false });
            })

    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            speed: 1000,
            autoplaySpeed: 3000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        };
        return (
            <div>
                <section className="testimonial-area">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2>Customers Feedback <a className="btn btn-info btn-sm" style={{ "border-radius": "20px", "margin-bottom": "5px" }}><i className="fa fa-star" aria-hidden="true"></i> {this.state.adminDetails.review} ({this.state.adminDetails.total_review})</a></h2>
                            <span className="border"></span>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="testimonials-carousel">

                                    <Slider {...settings}>
                                        {this.state.review.filter(gs => gs.review_status === 1).
                                            map((object, i) =>
                                                <div className="single-slide-item">
                                                    <div className="">


                                                        <div className="client-photo" style={{ "margin-left": "88px" }}>
                                                            {object.get_user.get_user1.length != 0 ?
                                                                object.get_user.get_user1.profile_image != '' ?
                                                                    <img src={`${CONSTANT.URL}/storage/${object.get_user.get_user1.profile_image}`} alt="Awesome Image" style={{ "border-radius": "10%", "margin-top": "5px",'margin-left':'450px' ,"margin-bottom": "10px", width: "10%" }} />
                                                                    :
                                                                    <img src="images/customers/customer-1.png" alt="Awesome Image" />
                                                                :
                                                                <img src="images/customers/customer-1.png" alt="Awesome Image" />
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="text-box">
                                                        <span className=""></span>
                                                        <div className="text">
                                                            <a className="btn btn-info btn-sm" style={{ "border-radius": "20px", "margin-bottom": "5px" }}><i className="fa fa-star" aria-hidden="true"></i> 4.8</a>
                                                            <p>
                                                                {object.comment}
                                                            </p>
                                                            <h3>{object.get_user.name}</h3>
                                                            <h4>{object.get_user.address}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
