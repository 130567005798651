import React from 'react'
import {toast} from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';
// import {UserContext} from './../../content/App'
toast.configure();
export function imageValid(e) {
    // console.log(e.target.id)
    var formData = new FormData();
    var id=e.target.id
    var file = document.getElementById(id).files[0];
    formData.append("Filedata", file);
    var t = file.type.split('/').pop().toLowerCase();
    if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
        toast.error ("Please Select image File ",  
        {position: toast.POSITION.BOTTOM_CENTER,autoClose:3000})
    
        document.getElementById(id).value = '';
        return false;
    }
    if (file.size > 2048000) {
        toast.error ("image File must be less than 2 MB ",  
        {position: toast.POSITION.BOTTOM_CENTER,autoClose:3000})
    
        document.getElementById(id).value = '';
        return false;
    }
    return true;
}

