import React, { Component } from 'react'

export default class HeaderSearch extends Component {
    render() {
        return (
            <div>
                <section className="header-search stricky ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="search-form pull-right">
                                    <form action="#">
                                        <div className="search">
                                            <input type="search" name="search" value="" placeholder="Search Something" />
                                            <button type="submit"><span className="icon fa fa-search"></span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
