import React, { Component } from 'react'
import queryString from 'query-string';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import * as CONSTANT from '../../utils/constant';
import { addToCart } from '../../services/Actions/actions.js';
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { checkAuth } from '../../utils/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShopLoader from '../Loader/ShopLoader';
import BackDrop1 from '../Loader/BackDrop1';
class Shop1 extends Component {
    constructor(props) {
    window.scrollTo(0, 0);

        super(props)
        let param = props.location.search;
        const parsed = queryString.parse(param);
        let a, b, c, d, e;

        if (parsed.cat_id == undefined) {
            a = "0"
        } else {
            a = parsed.cat_id
        }
        if (parsed.subcat_id == undefined) {
            b = "0"
        } else {
            b = parsed.subcat_id
        }
        if (parsed.service_name == undefined) {
            c = "0"
        } else {
            c = parsed.service_name
        }
        this.state = {
            cat: [],
            subcat: [],
            cat_id: a,
            subcat_id: b,
            service_name: c,
            sort: 0,
            serviceDetails: [],
            filter_count: 0,
            serviceImage: [],
            loading: false,
            loading1: false,

        }
    }
    componentDidMount() {
        this.fetchApi()
        get(`/api/showCategory`)
            .then((response) => {
                this.setState({
                    cat: response.data.data.get_category
                })
            })
            .catch(function (error) {
                console.log(error);
            });
        get(`/api/showSubcategory`)
            .then((response) => {
                // console.log(response.data.data.get_subcategory)
                this.setState({
                    subcat: response.data.data.get_subcategory
                })
            })
            .catch(function (error) {
                console.log(error);
            });

        get('/api/showServiceImage')
            .then((response) => {
                // console.log(response.data)
                this.setState({
                    serviceImage: response.data.serviceimage
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    fetchApi() {
        const data = {
            cat_id: this.state.cat_id,
            subcat_id: this.state.subcat_id,
            service_name: this.state.service_name,
            status: this.state.sort,
        }

        console.log(data)

        post('/api/showShotingService', data)
            .then((response) => {
                // console.log(response.data)
                this.setState({
                    serviceDetails: response.data.filter_details,
                    filter_count: response.data.total_filter,
                    loading: true,
                    loading1: false,

                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
            // console.log('ijkl')
            let param = this.props.location.search;
            const parsed = queryString.parse(param);
            let a, b, c, d, e;
            if (parsed.cat_id == undefined) {
                a = "0"
            } else {
                a = parsed.cat_id
            }
            if (parsed.subcat_id == undefined) {
                b = "0"
            } else {
                b = parsed.subcat_id
            }
            if (parsed.service_name == undefined) {
                c = "0"
            } else {
                c = parsed.service_name
            }
            this.setState({
  
                    cat_id: a,
                    subcat_id: b,
                    service_name: c, 
                    loading:false,
            })
            setTimeout(() => {
                this.fetchApi() 
            }, 1000);
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value,
            loading1:true,
        });
        setTimeout(() => {
            this.fetchApi() 
        }, 1000);
    }

    render() {
        return (
            <div>
                {this.state.loading1?
                <BackDrop1/>
                :null}
                {/* <section className="breadcrumb-area" style={{ "background-image": "url(images/breadcrumb/breadcrumb-bg.jpg)" }}>
                    <div className="container-fluid text-center">
                        <h1>Shop Fullwidth</h1>
                        <div className="breadcrumb-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="left pull-left">
                                            <ul>
                                                <li><a href="index-2.html">Home</a></li>
                                                <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                                <li><a href="shop.html">Shop</a></li>
                                                <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                                <li>Shop Fullwidth</li>
                                            </ul>
                                        </div>
                                        <div className="right pull-right">
                                            <a href="#"><i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Get a Quote</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {this.state.loading ?
                    <section className="shop-area">
                        <div className="container">
                            <div className="row showing-result-shorting">
                                <div className="col-md-12">
                                    <div className="showing pull-left">
                                        <p>Showing 1-12 of 15 results</p>
                                    </div>
                                    <div className="shorting pull-right">
                                        <select className="form-control" name="sort" onChange={this.handleChange} style={{ "border-radius": "25px" }}>
                                            <option selected="selected" disabled  selected>Default Sorting</option>
                                            <option value="1"> Newly Service</option>
                                            <option value="2"> Price High to Low</option>
                                            <option value="3"> Price Low to High</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.serviceDetails.length != 0?
                                this.state.serviceDetails.map((obj, i) =>
                                    <div className="col-md-3 col-sm-4 col-xs-12">
                                        <div className="single-shop-item">
                                            <div className="img-holder">
                                                {this.state.serviceImage.filter(gs => gs.service_id == obj.s_id).map((object, i) =>
                                                    i == 0 ?
                                                        <>
                                                            <img src={`${CONSTANT.URL}/storage/${object.service_icon}`} key={i} alt="Awesome Image" />
                                                        </> : null
                                                )}
                                                <div className="overlay">
                                                    <div className="box">
                                                        <div className="content">
                                                            <Link to={`projectdetails?id=${obj.service_id}`} className="">View Details</Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="title-holder">
                                                <div className="top clearfix">
                                                    <div className="product-title pull-left">
                                                        <h5>{obj.service_name}</h5>
                                                    </div>
                                                    <div className="review-box pull-right">
                                                        {!checkAuth.isAuthenticated ?
                                                            <button className="btn btn-success" onClick={() => {
                                                                toast.dark("Login Your Account",
                                                                    { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                                                            }}>Watch Later
                                                    </button>
                                                            :
                                                            <button className="btn btn-success" onClick={() => this.props.adtocart(obj.s_id)}>Watch Later</button>
                                                        }
                                                    </div>
                                                </div>
                                                <h4>₹ {obj.service_price}</h4>
                                            </div>
                                        </div>
                                    </div>
                                ):
                                <center><h1>Service Not Found</h1></center>
                            }

                            </div>
                        </div>
                    </section>
                    :
                    <ShopLoader />
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        catdetails: state.addItems,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adtocart: (data) => { dispatch(addToCart(data)) },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Shop1);

