import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, withRouter } from 'react-router-dom'
import * as CONSTANT from '../../utils/constant';
import CopyText from 'react-copy-text'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import GreadLoader from '../Loader/GreadLoader';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ display: "block", position: "absolute", right: "23px", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}
            onClick={onClick}
        >
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick} style={{ display: "block", position: "absolute", left: "23px", zIndex: "10", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}>
        </div>
    );
}


export default class Coupon extends Component {
    constructor(props) {
        super(props)

        this.state = {
            coupon: [],
            textToCopy: '',
            loading: false,

        }
    }
    onButtonClick = (code) => {
        this.setState({ textToCopy: code })
    }
    onCopied = (text) => {
        //    const id = toast.success(`${text} copied `);
        toast.dark(`${text} copied `,
            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

        // console.log(`${text} was copied to the clipboard`)
    }
    componentDidMount() {
        get('/api/viewcoupon')
            .then((data) => {
                console.log(data)
                this.setState({
                    coupon: data.data.allcoupon,
                    loading: true,
                })
            }).catch((err) => {
                this.setState({ loading: false });
            })
    }

    render() {
        var settings = {
            dots: true,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
                <CopyText text={this.state.textToCopy} onCopied={this.onCopied} />

                <section className="breadcrumb-area" style={{ backgroundImage: "url(image_gg/bg_image/my_offer.jpg)" }}>
                    <div className="container-fluid text-center">
                        <h1>MY OFFER</h1>
                        <div className="breadcrumb-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="left pull-left">
                                            <ul>
                                                <li><Link to="/">Home</Link></li>

                                                <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>

                                                <li>Offer</li>
                                            </ul>
                                        </div>
                                        <div className="right pull-right">
                                            <a href="javascript:void(0);"><i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Get a Quote</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="pricing-table-area">
                    <div className="container">
                        <div className="sec-title">
                            <h2>My <span>Offer</span></h2>
                            <span className="decor"></span>
                        </div>
                        <div className="row">
                            {this.state.loading === false ?
                                <GreadLoader /> :

                                this.state.coupon.map((object, i) =>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 text-center">
                                        <div className="single-price-box">
                                            <div className="table-header">
                                                <h4 style={{ "color": "white" }}>{object.coupon_name}</h4>
                                            </div>
                                            <div className="price">
                                                <h3 style={{ "color": "black" }}><span>Flat </span> ₹ {object.coupon_value}<b> off</b></h3>
                                            </div>
                                            <div className="price-list">
                                                <ul>
                                                    <li>For {object.get_service_coupon.service_name} Service</li>

                                                </ul>
                                            </div>
                                            <div className="table-footer">
                                                <a href="javascript:void(0)" onClick={() => this.onButtonClick(object.coupon_code)}>{object.coupon_code}</a>
                                            </div>
                                        </div>
                                    </div>




                                )

                            }

                        </div>



                    </div>
                </section>



            </div>
        )
    }
}
