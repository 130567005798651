import React, { Component } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import { authget, get } from '../../utils/service'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import * as CONSTANT from '../../utils/constant';

export default class CustomizeDesign extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0);

        this.state = {
            open: false,
            open1: false,
            enquery: [],
         
            details: [],
        }
    }
    componentDidMount() {
        authget(`/api/viewCustomize`)
            .then((response) => {
                console.log(response.data)
                const order = response.data.data;
                this.setState({
                    details: order,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
  
    onCloseModal1 = () => {
        this.setState({
            open1: false,
        })
    }
    render() {
        return (
            <div>

                <Modal open={this.state.open1} onClose={this.onCloseModal1} center 
                classNames={{
                    modal: 'customModal1',
                }}
                >
                    <h2 style={{ paddingLeft: "10px" }}>Details </h2><hr />
                    <div className="card">
                        <div className="card-body">
                            <ul id="progress">
                                <li><div class="node green"></div><p>The First Thing!</p></li>
                                <li><div class="divider green"></div></li>
                                <li><div class="node green"></div><p>The Second Thing!</p></li>
                                <li><div class="divider grey"></div></li>
                                <li><div class="node grey"></div><p>The Third Thing!</p></li>
                                <li><div class="divider grey"></div></li>
                                <li><div class="node grey"></div><p>The Fourth Thing!</p></li>
                                <li><div class="divider grey"></div></li>
                                <li><div class="node grey"></div><p>The Last Thing!</p></li>
                            </ul>
                        </div>
                    </div>

                </Modal>
                <section id="shopping-cart-area" className="checkout-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="shopping-cart-table">
                                    <div className="table">
                                        <table className="cart-table">
                                            <thead className="cart-header">
                                                <tr>
                                                    <th className="product-column">Name</th>
                                                    <th className="product-column">City Name</th>
                                                    <th>Phone;</th>
                                                    <th>Email</th>
                                                    <th>Message</th>
                                                    <th className="price">Doc</th>
                                                    <th className="price">Traking</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.details.map((obj, i) =>
                                                    <tr>
                                                        <td className="single-price">
                                                        <div className="product-title">
                                                                    <h5>{obj.name}</h5><br/>
                                                                    <p>{obj.order_id}</p>
                                                                </div>
                                                        </td>
                                                        <td className="single-price">
                                                            <h5>{obj.get_city_customize.city_name}</h5>
                                                        </td>
                                                        <td className="single-price">
                                                            <h5>{obj.phone}</h5>
                                                        </td>
                                                        <td className="single-price">
                                                            <h5>{obj.email}</h5>
                                                        </td>
                                                        <td className="single-price">
                                                            <h5>{obj.message}</h5>
                                                        </td>
                                                        
                                                        <td  className="product-column">
                                                            <div className="column-box">
                                                                <div className="prod-thumb">
                                                                  {obj.doc !== null?
                                                                               <a href={`${CONSTANT.URL}/storage/${obj.doc}`} target="_blank"> <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" alt="Awesome Image" key={i} /></a> 
                                                                            :
                                                                    null
                                                                  }
                                                                </div>
                                                                
                                                            </div>
                                                        </td>

                                                          <td>
                                                            <button className="btn btn-success" onClick={() => this.setState({ open1: true })}>Traking</button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                
            </div>
        )
    }
}
