import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ display: "block", position: "absolute", right: "23px", backgroundColor: "rgb(255 255 255 / 38%)", height: "auto", width: "auto", padding: "10px" }}
      onClick={onClick}
    >
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick} style={{ display: "block", position: "absolute", left: "23px", zIndex: "10", backgroundColor: "rgb(255 255 255 / 38%)", height: "auto", width: "auto", padding: "10px" }}>
    </div>
  );
}

export default class BrandArea extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
        <section className="brand-area">
          <div className="container clearfix">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="brand">
                    <Slider {...settings}>
                      <div className="single-item">
                        <a href="http://arcreation.co.in/" target="_blank"><img src="image_gg/brand_logo/arcreation_logo.jpg" alt="Awesome Brand Image" /></a>
                      </div>

                      <div className="single-item">
                        <a href="https://edistrict.wb.gov.in/PACE/login.do" target="_blank"><img src="image_gg/brand_logo/edistrict_logo.jpg" alt="Awesome Brand Image" /></a>
                      </div>

                      <div className="single-item">
                        <a href="https://www.grihaindia.org/about-griha" target="_blank"><img src="image_gg/brand_logo/griha _logo.jpg" alt="Awesome Brand Image" /></a>
                      </div>

                      <div className="single-item">
                        <a href="https://igbc.in/igbc/" target="_blank"><img src="image_gg/brand_logo/igbc_logo.jpg" alt="Awesome Brand Image" /></a>
                      </div>

                     
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>
    )
  }
}
