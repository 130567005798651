import React from 'react'
import Skeleton from "react-loading-skeleton";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
var settings = {
    dots: true,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", marginRight: "35px", fontFamily: "Roboto" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", marginLeft: "35px", zIndex: 1 }}
            onClick={onClick}
        />
    );
}
export default function SubcategoryLoader() {
  window.scrollTo(0, 0);

    return (
        <div>
              <section className="working-area">
          <div className="container ">
            <div className="sec-title">
            <Skeleton  height={10} />


           
            </div>
            <div className="row">
              <Slider {...settings}>
              
                {[1,2,3,4,5,6,7,8,9,].map((obj, i) =>
                  <div className="item">
                    <div className="dir-img">
                          <Skeleton  height={200} />

                    </div>
                  </div>
                )}




              </Slider>
            </div>
          </div>
        </section>
    
        </div>
    )
}
