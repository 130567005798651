import React,{useEffect,useState} from 'react'
import {showCart} from '../../services/Actions/actions.js';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import * as CONSTANT from '../../utils/constant';
import { Link, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {connect} from 'react-redux';
 function Cart(props) {
    const [image, setImage] = useState([]);
    const [cart, setCart] = useState([])
    const [deleteid, setDeleteid] = useState('')
    window.scrollTo(0, 0);

    useEffect(() => {
    
        get('/api/showServiceImage')
        .then((response) => {
            console.log(response.data)
            setImage(response.data.serviceimage)
        })
        .catch(function (error) {
            console.log(error);
        });
        var user_id = localStorage.getItem('user_id');
        authget(`/api/showCart?user_id=${user_id}`)
        .then((response) => {
            console.log(response.data)
            setCart(response.data.data)
        })
        .catch(function (error) {
            console.log(error);
        });

    },[]);
    const deletecart =(cart_id)=>{
        authget(`/api/removeCart?cart_id=${cart_id}`)
        .then((response) => {
            if(response.data.success === true){
                toast.dark("Service Remove From cart",
                { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 }) 
                   setCart(cart.filter(gs=>gs.id !== cart_id))
                   props.showCart();
            }
           
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    return (
        <div>
            <section id="shopping-cart-area" className="checkout-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="shopping-cart-table">
                                <div className="table">
                                    <table className="cart-table">
                                        <thead className="cart-header">
                                            <tr>
                                                <th className="product-column">Your Products</th>
                                                <th>&nbsp;</th>
                                                <th>Price</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                         {
                                          cart.map((obj,i)=>
                                            <tr>
                                                <td colspan="2" className="product-column">
                                                    <div className="column-box">
                                                        <div className="prod-thumb">
                                                            {image.filter(gs => gs.service_id == obj.service_id).map((object, i) =>
                                                                i == 0 ?
                                                                    <>
                                                                        <Link to={`projectdetails?id=${obj.get_service_cart.service_id}`}><img src={`${CONSTANT.URL}/storage/${object.service_icon}`} key={i} alt="" /></Link>
                                                                    </> : null
                                                            )}
                                                        </div>
                                                        <div className="product-title">
                                                            <h3>{obj.get_service_cart.service_name}</h3>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="single-price">
                                                    <h5>₹{obj.get_service_cart.service_price}</h5>
                                                </td>

                                                <td>
                                                    <div className="remove">
                                                        <span className="flaticon-arrows" onClick={()=>deletecart(obj.id)}></span>
                                                    </div>
                                                </td>

                                            </tr>
                                           )} 
                                         
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}


const mapStateToProps=(state)=>{
	console.log(state);
	return{
	  catdetails: state.addItems,
	}
  }

  const mapDispatchToProps=(dispatch)=>{
    return{
        showCart:()=>{ dispatch(showCart())},
    }
}

  
export default connect(mapStateToProps,mapDispatchToProps) (Cart);

