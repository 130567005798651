import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, withRouter } from 'react-router-dom'
import * as CONSTANT from '../../utils/constant';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import GreadLoader from '../Loader/GreadLoader';
var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ display: "block", position: "absolute", right: "23px", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}
      onClick={onClick}
    >
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick} style={{ display: "block", position: "absolute", left: "23px", zIndex: "10", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}>
    </div>
  );
}


export default class Category extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cat: [],
      loading: false
    }
  }


  componentDidMount() {
    get(`/api/showCategory`)
      .then((response) => {
        const catd = response.data.data.get_category.filter(gs => gs.cat_status == 1)
        this.setState({
          cat: catd,
          loading: true,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  render() {
    var settings = {
      dots: false,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: false,
      speed: 500,
      autoplaySpeed: 3000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
        <section className="latest-blog-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="sec-title pull-left">
                  <h2>gallery</h2>
                  <span className="decor"></span>
                </div>
              </div>
            </div>

            {this.state.loading === false ?
              <GreadLoader /> :
              <div className="row">
                <Slider {...settings}>
                  {this.state.cat.map((obj, i) =>
                 
                    <div className="col-md-4">
                      <div className="single-blog-item">
                        <div className="img-holder">
                          <img src={`${CONSTANT.URL}/storage/${obj.cat_icon}`} alt="Awesome Image" />
                          <div className="overlay">
                            <div className="box">
                              <div className="content">
                                {obj.cat_role === 1 ?
                                  <Link to={`phq?cat_id=${urlCrypt.cryptObj(obj.id)}`}>
                                    <i className="fa fa-link" aria-hidden="true"></i>
                                  </Link>
                                  :
                                  <Link to={`shop?cat_id=${obj.cat_id}`}>
                                    <i className="fa fa-link" aria-hidden="true"></i>
                                  </Link>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-holder">
                          {obj.cat_role === 1 ?
                            <Link to={`phq?cat_id=${urlCrypt.cryptObj(obj.id)}`}>

                              <h3 className="blog-title">{obj.cat_name}</h3>
                            </Link>
                            :
                            <Link to={`shop?cat_id=${obj.cat_id}`}>
                              <h3 className="blog-title">{obj.cat_name}</h3>
                            </Link>
                          }

                        </div>
                      </div>
                    </div>
               
           
                )}
                </Slider>
            
              </div>
            }
          </div>
        </section>


      </div>
    )
  }
}
