import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator';
// import { imageValid } from '../../utils/imageValid';
import * as CONSTANT from '../../utils/constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import BackDrop1 from '../Loader/BackDrop1';

export default class Carear extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            phone: '',
            cv: '',
            loading: false,
        }
        this.validator = new SimpleReactValidator({
            messages: {
                in: 'Passwords need to match!'
            }
        })
        window.scrollTo(0, 0);
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        var formdata = new FormData(e.target);
        if (this.validator.allValid()) {
            authformpost('/api/addCarear', formdata)
                .then((data) => {
                    // console.log(data.data)

                    if (data.data.status === true) {
                        toast.dark(data.data.msg,
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

                    } else {
                        toast.dark(data.data.msg,
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }

    imageValid = (e) => {
        // console.log(e.target.id)
        var formData = new FormData();
        var id = e.target.id
        var file = document.getElementById(id).files[0];
        formData.append("Filedata", file);
        var t = file.type.split('/').pop().toLowerCase();
        if (t != "pdf" && t != "PDF") {
            toast.error("Please Select image or pdf File ",
                { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

            document.getElementById(id).value = '';
            return false;
        }
        if (file.size > 2048000) {
            toast.error("File must be less than 2 MB ",
                { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

            document.getElementById(id).value = '';
            return false;
        }
        return true;
    }


    render() {

        return (
            <div>
                {this.state.loading === true ?
                    <BackDrop1 />
                    : null
                }

                <section id="blog-area" className="blog-single-area ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                <div className="blog-post">
                                    <div className="single-blog-item">
                                        <div className="text-holder">
                                            <div className="sec-title-two">
                                                <h3>Career </h3>
                                            </div>
                                            {/* <ul className="meta-info">
                                                <li><i className="fa fa-user" aria-hidden="true"></i><a href="#">Fletcher</a></li>
                                                <li><i className="fa fa-clock-o" aria-hidden="true"></i><a href="#">April 21, 2016</a></li>
                                                <li><i className="fa fa-folder-open-o" aria-hidden="true"></i><a href="#">Residential</a></li>
                                                <li><i className="fa fa-comments" aria-hidden="true"></i><a href="#">14</a></li>
                                            </ul> */}
                                            <div className="text">
                                                <p className="mar-bottom">
                                                Griha Nirman Guide, a platform with best designers, from designing styles of residence to interior designing to construction, is looking for aspirants with a vast knowledge in his/her field. We are seeking for designers with unique ideas. A platform which stands out from the rest and fulfils dreams and visions of our clients. We specialize in designing residences and interior, with unique ideas. We intend to give our design team extensive experience, and hope to expand your knowledge to a greater form.
                                               </p>
                                               
                                            </div>
                                        </div>
                                    </div>

                                    <div className="middle-content-box">
                                        <div className="row">
                                            <div className="col-md-12">
                                               
                                                <div className="">
                                               
                                                    <h3 style={{color:"black"}}>OUR MISSION</h3>
                                                    <p>To ensure and achieve our clients’ demands and necessities, and to surpass expectations with regard to organisation, standardization and comfortability. </p>
                                                   <br/>
                                                    <h3 style={{color:"black"}}>OUR VISION</h3>
                                                    <p>To inevitably provide star rated products with sincere team of designers, to give our clients the best experience.</p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-7 col-xs-12">

                                <div className="form register account-area bgc" >

                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="field-label text-color black">Name</div>
                                                <div className="field-input">
                                                    <input type="text" className="form-control" name="name" placeholder="Enter Your Name" value={this.state.name} onChange={this.handleChange} style={{ "height": "43px" }} />
                                                    {this.validator.message('name', this.state.name, 'required', { className: 'text-danger' })}

                                                </div>
                                            </div>
                                            <div className="col-md-12"><br />
                                                <div className="field-label text-color black">Email Id (Optional) :</div>
                                                <div className="field-input">

                                                    <input type="email" name="email" className="form-control" placeholder="Enter Email" value={this.state.email} onChange={this.handleChange} style={{ "height": "43px" }} />

                                                </div>
                                            </div>
                                            <div className="col-md-12"><br />
                                                <div className="field-label text-color black">Phone Number :</div>
                                                <div className="field-input">
                                                    <input type="number" className="form-control" name="phone" value={this.state.phone} onChange={this.handleChange} placeholder="Enter Phone Number" style={{ "height": "43px" }} />
                                                    {this.validator.message('phone', this.state.phone, 'required', { className: 'text-danger' })}

                                                </div>
                                            </div>
                                            <div className="col-md-12"><br />
                                                <div className="field-label text-color black">Upload Resume : </div>
                                                <div className="field-input">
                                                    <input type="file" name="cv" id="cv" className="form-control" onChange={(event) => { this.imageValid(event) }} placeholder="" style={{ "height": "43px" }} required />

                                                </div>
                                            </div>

                                            <div className="col-md-12 col-sm-12 col-xs-12"><br />
                                                <button className="thm-btn bg-cl-1" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>



            </div>
        )
    }
}
