import React, { Component } from 'react'
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator';
import * as CONSTANT from '../../utils/constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authput, authget, authpost, authformpost, authdelete, get } from '../../utils/service'
import BackDrop1 from '../Loader/BackDrop1';

export default class Checkout extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0);

        let param = props.location.search;
        const parsed = queryString.parse(param);
        this.state = {
            service_id: parsed.id,
            service_iid:'',
            city: [],
            city_id: '',
            user_id: localStorage.getItem('user_id'),
            name: localStorage.getItem('userName'),
            email: localStorage.getItem('userEmail'),
            phone: localStorage.getItem('userPhone'),
            address: '',
            coupon_amount: 0,
            service: [],
            transaction_id:'',
            coupon_code: '',
            classd: '',
            msg: '',
            client_msg: '',

        }
        this.validator = new SimpleReactValidator({
            messages: {
                in: 'Passwords need to match!'
            }
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const data = {
          'service_id': this.state.service_id,
          'city_id': this.state.city_id,
          'user_id': this.state.user_id,
          'service_id': this.state.service.id,
          'client_name': this.state.name,
          'client_email': this.state.email,
          'client_phone': this.state.phone,
          'client_address': this.state.address,
          'transaction_id': this.state.transaction_id,
          'client_msg': this.state.client_msg,
          'service_price': this.state.service.service_price,
          'with_coupon_price':(this.state.service.service_price)-(this.state.coupon_amount),
        }
        if (this.validator.allValid()) {
          this.setState({
            loading: true,
          });
          authpost("/api/addEnquery", data)
            .then((res) => {
              console.log(res);
              if (res.status == 404 || res.status == 400) {
                this.setState({
                  loading: false,
                });
                toast.error(res.data.detail, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  autoClose: 3000,
                });
              } else {
                this.setState({
                  loading: false,
                });
                toast.dark("Booking Successfully", {
                  position: toast.POSITION.BOTTOM_CENTER,
                  autoClose: 3000,
                });
                this.props.history.push("/successfull");
              }
            })
    
            .catch((err) => {
              console.log(err);
              this.setState({
                loading: false,
              });
            });
        } else {
          this.validator.showMessages();
          this.forceUpdate();
          this.setState({
            loading: false,
          });
        }
      };
    

    componentDidMount() {
        get(`/api/viewCity`)
            .then((response) => {
                console.log(response.data.city)
                const da = response.data.city.filter(gs => gs.city_status === 1)
                this.setState({
                    city: da
                })
            })
            .catch(function (error) {
                console.log(error);
            });

        get(`/api/showIndivisualService?service_id=${this.state.service_id}`)
            .then((response) => {

                this.setState({
                    service: response.data.service,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    applyCoupon = () => {
        const data = {
            service_id: this.state.service.id,
            coupon_code: this.state.coupon_code,
        }

        authpost(`/api/applycouponcode`, data)
            .then((response) => {
                if (response.data.success === true) {
                    this.setState({
                        coupon_amount: response.data.couponvalue,
                        msg: response.data.msg,
                        classd: 'success'
                    })
                } else {
                    this.setState({
                        coupon_amount: 0,
                        msg: response.data.msg,
                        classd: 'danger'
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                <section className="checkout-area">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-6">
                                <div className="">
                                    <div className="sec-title-two">
                                        <h3>Address</h3>
                                    </div>
                                    <form >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="field-label"> Select Nearest City*</div>
                                                <div className="field-input">
                                                    <select name="city_id" id="city_id" className="form-control" onChange={this.handleChange} style={{ "height": "43px" }}>
                                                        <option value="" disabled selected>- Select City -</option>
                                                        {this.state.city.map((object, i) =>
                                                            <option value={object.id}>{object.city_name}</option>
                                                        )}
                                                    </select>
                                                    {this.validator.message(
                                                        "city_id",
                                                        this.state.city_id,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-12"><br />
                                                <div className="field-label">Name *</div>
                                                <div className="field-input">
                                                    <input type="text" name="name" id="name" placeholder="Enter Name" className="form-control" value={this.state.name} onChange={this.handleChange} style={{ "height": "43px" }} />
                                                    {this.validator.message(
                                                        "name",
                                                        this.state.name,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6"><br />
                                                <div className="field-label">Contact No *</div>
                                                <div className="field-input">
                                                    <input type="number" name="phone" id="phone" placeholder="Enter Contact No" className="form-control" value={this.state.phone} onChange={this.handleChange} style={{ "height": "43px" }} />
                                                    {this.validator.message(
                                                        "phone",
                                                        this.state.phone,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6"><br />
                                                <div className="field-label">Email Id *</div>
                                                <div className="field-input">
                                                    <input type="email" name="email" id="email" placeholder="Email Id" className="form-control" value={this.state.email} onChange={this.handleChange} style={{ "height": "43px" }} />
                                                    {this.validator.message(
                                                        "email",
                                                        this.state.email,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>
                                            </div>


                                            <div className="col-md-12"><br />
                                                <div className="field-label">Address</div>
                                                <div className="field-input">
                                                    <input type="text" name="address" id="address" placeholder="Enter Address" className="form-control" value={this.state.address} onChange={this.handleChange} style={{ "height": "43px" }} />
                                                    {this.validator.message(
                                                        "address",
                                                        this.state.address,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6"><br />
                                                <div className="field-label">Message (Optional)</div>
                                                <div className="field-input">
                                                    <input type="text" name="client_msg" id="client_msg" placeholder="Enter Address" className="form-control" value={this.state.client_msg} onChange={this.handleChange} style={{ "height": "43px" }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6"><br />
                                                <div className="field-label">Transaction ID</div>
                                                <div className="field-input">
                                                    <input type="text" name="transaction_id" id="transaction_id" placeholder="Enter Transaction ID" className="form-control" value={this.state.transaction_id} onChange={this.handleChange} style={{ "height": "43px" }} />
                                                    {this.validator.message(
                                                        "transaction_id",
                                                        this.state.transaction_id,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className=" col-md-6">
                                <div className="cart-total">
                                    <div className="sec-title-two ">
                                        <h3 className="paddingTop">Service Details</h3>
                                    </div>
                                    <ul className="cart-total-table">
                                        <li className="clearfix">
                                            <span className="col col-title">Service Name</span>
                                            <span className="col">{this.state.service.service_name}</span>
                                        </li>
                                        <li className="clearfix">
                                            <span className="col col-title">Service Amount</span>
                                            <span className="col">₹ {this.state.service.service_price}</span>
                                        </li>
                                        <li className="clearfix">
                                            <span className="col col-title">Coupon Amount</span>
                                            <span className="col">₹ {this.state.coupon_amount}</span>
                                        </li>
                                        <li className="clearfix">
                                            <span className="col col-title">Payable Amount</span>
                                            <span className="col">₹ {(this.state.service.service_price) - (this.state.coupon_amount)}</span>
                                        </li>
                                    </ul>
                                    <div className="payment-options">
                                        <div className="option-block">
                                            <div className="row">
                                                <div className="col-md-10 ">
                                                    <input type="text" className="form-control" name="coupon_code" id="coupon_code" placeholder="Apply Coupon Code" value={this.state.coupon_code} onChange={this.handleChange} style={{ "height": "43px" }}></input>
                                                    <div className={`text-${this.state.classd}`} ><b>{this.state.msg}</b></div>
                                                </div>
                                                <div className="col-md-2 couponbutton">
                                                    <button className="btn btn-success" style={{ "height": "43px" }} onClick={this.applyCoupon}> Apply</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="placeorder-button text-center">
                                       
                                            <button type="submit"  onClick={this.handleSubmit}
                                            disabled={this.state.loading}> {this.state.loading ? "Loading.." : "Place Order"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </div>
        )
    }
}
