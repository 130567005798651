import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, withRouter } from 'react-router-dom'
import * as CONSTANT from '../../utils/constant';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import queryString from 'query-string';
import SubcategoryLoader from '../Loader/SubcategoryLoader';
var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ display: "block", position: "absolute", right: "23px", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}
      onClick={onClick}
    >
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick} style={{ display: "block", position: "absolute", left: "23px", zIndex: "10", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}>
    </div>
  );
}

export default class SubCategory extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      cat: [],
      loading:false,
    }
  }


  componentDidMount() {
    get(`/api/showSubcategory`)
      .then((response) => {
        const catd = response.data.data.get_subcategory.filter(gs => gs.subcat_status == 1 && gs.cat_id == this.props.cat_id)
        console.log(catd)
        this.setState({
          cat: catd,
          loading:true
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    var settings = {
      dots: true,
      infinite: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 3000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
        {this.state.loading?
        <section className="working-area">
          <div className="container ">
            <div className="sec-title">
              <h3 style={{ "color": "black" }}>Sub Category</h3>

              <span className="decor"></span>
            </div>
            <div className="row">
              <Slider {...settings}>

                {this.state.cat.map((obj, i) =>
                  <div className="item">
                    <div className="dir-img">
                      <Link to={`shop?subcat_id=${obj.subcat_id}`}>
                        <div className="h-1">   <img src={`${CONSTANT.URL}/storage/${obj.subcat_icon}`} alt="Awesome Image" /></div>
                        <p>{obj.subcat_name}</p>
                      </Link>
                    </div>
                  </div>
                )}




              </Slider>
            </div>
          </div>
        </section>
         :
         <SubcategoryLoader/>
         }
      </div>
    )
  }
}
