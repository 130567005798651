import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import * as CONSTANT from "../../utils/constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    authput,
    authget,
    post,
    authformpost,
    authdelete,
    get,
} from "../../utils/service";
import BackDrop1 from "../Loader/BackDrop1";
export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);

        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            loading: false,
        };
        this.validator = new SimpleReactValidator({
            messages: {
                in: "Passwords need to match!",
            },
        });
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    };
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        if (this.validator.allValid()) {
            authformpost("/api/contactUs", formdata)
                .then((data) => {
                    console.log(data.data);
                    if (data.data.status === true) {
                        toast.dark('Contact With Us Successfully',
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

                    } else {
                        toast.dark('Did Not Contact With Us',
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    }
                    this.setState({ loading: false });
                })
                .catch((err) => {
                    this.setState({ loading: false });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    };

    render() {
        return (
            <div>
                {this.state.loading ? <BackDrop1 /> : null}

                <section className="contact-v1-area">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2>Get In Touch With Us</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="img-holder">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7395.406148839591!2d88.081068!3d22.060951!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x91afaed3792b59da!2sArcreation%20Design%20Private%20Limited!5e0!3m2!1sen!2sin!4v1619201599473!5m2!1sen!2sin"
                                        width="350"
                                        height="200"
                                        style={{ border: 0 }}
                                        allowfullscreen=""
                                        loading="lazy"
                                    ></iframe>
                                </div>
                                <div className="contact-info">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="single-item">
                                                <div className="icon-holder">
                                                    <span className="flaticon-building"></span>
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Address:</h5>
                                                    <p>
                                                        Plot No. 300, Debhog, City Centre, Haldia, Purba
                                                        Medinipur, West Bengal – 721657{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-5">
                                            <div className="single-item">
                                                <div className="icon-holder">
                                                    <span className="flaticon-technology"></span>
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Phone:</h5>
                                                    <p>
                                                        <b>
                                                            +91 85879 97907 <br />
                                                            +91 8170 9355 99
                                                        </b>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-7">
                                            <div className="single-item">
                                                <div className="icon-holder">
                                                    <span className="flaticon-new-email-outline"></span>
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Email Address:</h5>
                                                    <p>support@grihanirmanguide.com</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-5">
                                            <div className="single-item">
                                                <div className="icon-holder">
                                                    <span className="flaticon-clock"></span>
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Working Hours:</h5>
                                                    <p>Monday to Saturday 9am to 6pm</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contact-form-v1">
                                    <form onSubmit={this.handleSubmit}>
                                        <input
                                            type="text"
                                            name="name"
                                            defaultValue=""
                                            placeholder="Your Name*"
                                            required
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            defaultValue=""
                                            placeholder="Your Mail*"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="phone"
                                            defaultValue=""
                                            placeholder="Phone"
                                            required
                                        />
                                        <textarea
                                            name="message"
                                            placeholder="Your Message.."
                                            defaultValue=""
                                        ></textarea>

                                        <button
                                            className="thm-btn bg-cl-1"
                                            type="submit"
                                            data-loading-text="Please wait..."
                                        >
                                            send message
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
