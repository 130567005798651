import React from 'react'
import { Link, withRouter } from 'react-router-dom'

export default function Page_404() {
    window.scrollTo(0, 0);
    return (
        <div>
            <section className="not-found-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="not-found-content text-center">
                                <h1>404</h1>
                                <h3>OOPPS! THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.</h3>
                                <p>Go To the Home</p>

                                <Link to="/" className="btn btn-success">Home</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
