import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(35),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
export default function Privacy_policy() {
  const classes = useStyles();
  window.scrollTo(0, 0);

  return (
    <div>
      {/* <section className="breadcrumb-area" style={{"background-image": "url(images/breadcrumb/breadcrumb-bg.jpg)"}}>
                <div className="container-fluid text-center">
                    <h1>FAQ’s</h1>
                    <div className="breadcrumb-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="left pull-left">
                                        <ul>
                                            <li><a href="index-2.html">Home</a></li>
                                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                            <li><a href="index-2.html">About Us</a></li>
                                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                            <li>FAQ’s</li>
                                        </ul>
                                    </div>
                                    <div className="right pull-right">
                                        <a href="#"><i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Get a Quote</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      <section className="faq-content-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="accordion-box">
                <div className={classes.root}>
                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        PRIVACY POLICY:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        This Privacy Policy relates solely to the user
                        information collected by us and how that information is
                        treated on our Application, GNG. We recognize that many
                        visitors and users of “GNG” are concerned about the
                        information they provide to us and how we use that
                        information. This Privacy Policy has been developed to
                        address those concerns. At GNG, we respect your privacy
                        and take reasonable measures to protect your personal
                        information and other details that you share with us.
                        ANY ACCESS OR USE OF THIS APPLICATION (GNG) WOULD MEAN
                        THAT YOU HAVE READ, UNDERSTOOD AND AGREED TO ADHERE TO
                        ALL THE TERMS OF THIS PRIVACY POLICY AND OUR APPLICATION
                        TERMS AND CONDITIONS. IN CASE YOU DISAGREE TO ANY OR ALL
                        OF THESE TERMS, PLEASE LEAVE THIS PAGE AND DO NOT ACCESS
                        OR USE THE APPLICATION.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        LANGUAGE OF THE TERMS:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        The terms are incorporated herein in English language
                        and the application does not provide you with a
                        translation of the English language version of the Terms
                        and if there is any contradiction between what the
                        English language version of the Terms says and what a
                        translation says, then the English language version
                        shall take precedence.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className={classes.heading}>
                        CHANGES TO PRIVACY POLICY:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        GNG reserves the right to update this Privacy Policy
                        from time to time as it may deem fit and proper without
                        any prior notice. These changes, introduced to the
                        Privacy Policy upon being posted on GNG, will take
                        effect immediately. So do make sure you are aware of the
                        changes; please review this page periodically to ensure
                        you have the latest version. After such revisions take
                        effect, your continued use of the APP, GNG, will
                        indicate your acceptance and acknowledgment of the terms
                        of the revised Privacy Policy.{" "}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className={classes.heading}>
                        TYPES OF INFORMATION COLLECTED AND USES OF COLLECTED
                        INFORMATION:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        During your use of GNG, we will collect and store your
                        personal information which is provided by you from time
                        to time. Such information is essential to guarantee you
                        a safe, secure, proficient and personalized shopping
                        experience.
                        <br /> Any individual can browse our application without
                        sharing his/her personal information with us.
                        <br />
                        However, if you intend to become a member, it becomes
                        necessary to share all your personal details (as
                        required) with us. We hope that this Policy helps you
                        understand what kind of personal data, if any, we
                        collect at this application and how we handle and use
                        any such personal data after collection. The various
                        types of information that we collect from our customers
                        are mentioned herein below:
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className={classes.heading}>
                        Personally Identifiable Information:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        “Personally Identifiable Information” is the information
                        that identifies a specific End User. Such unique
                        identification and the subsequent submission of data are
                        directly associated with your various activities at GNG
                        such as the creation of the account, placing an order,
                        sharing your feedback, suggesting a product etc.
                        However, it is for you to choose not to engage in such
                        activities.
                        <br />
                        Some of the information that we collect includes:
                        <ul>
                          <li>Name</li>
                          <li>Mobile numbers,</li>
                          <li>Email IDs</li>
                          <li>
                            Any other personal information that may be supplied
                            by you or collected about you from time to time
                            according to the applicable local legislation.
                          </li>
                        </ul>
                        Depending on your activity on GNG, some of the details
                        we may ask are mandatory and some are optional. If you
                        do not provide the mandatory information for a
                        particular activity that requires it, you will not be
                        permitted to engage in that activity.
                        <p>
                          The various purposes for which we need this
                          information include, but not limited to:{" "}
                        </p>
                        <ul>
                          <li>Verifying you as a legitimate user.</li>
                          <li>
                            Allowing you to place your orders for our products
                            and services.
                          </li>
                          <li>Informing you about your order status.</li>
                          <li>Delivering the ordered services.</li>
                        </ul>
                        <p>
                          This information may also be used to analyze
                          Application use, enhance our services for you, improve
                          our marketing and promotional activities, administer
                          our application and address your shopping preferences.
                          We may also use Personally Identifiable Information to
                          resolve disputes, enforce our agreements with you
                          (including our Application Terms of Use and this
                          Privacy Policy), comply with applicable law and
                          cooperate with law enforcement activities. We assure
                          that your Personally Identifiable Information are
                          private and secure at GNG and will not be sold, rented
                          or traded to any third party entity without your
                          explicit consent. Only the staff, officers, employees,
                          agents and representatives of GNG will view your
                          information. However, we may disclose your personal
                          information if required to do so as detailed in the
                          ‘Sharing of Personal Information” mentioned
                          hereinafter.
                        </p>
                        <p>
                          We may also use Personally Identifiable Information to
                          resolve disputes, enforce our agreements with you
                          (including our Application Terms of Use and this
                          Privacy Policy), comply with applicable law and
                          cooperate with law enforcement activities.
                        </p>
                        <p>
                          We may also use Personally Identifiable Information to
                          resolve disputes, enforce our agreements with you
                          (including our Application Terms of Use and this
                          Privacy Policy), comply with applicable law and
                          cooperate with law enforcement activities.
                        </p>
                        <p>
                          Only the staff, officers, employees, agents and
                          representatives of GNG will view your information.
                        </p>
                        <p>
                          However, we may disclose your personal information if
                          required to do so as detailed in the ‘Sharing of
                          Personal Information” mentioned hereinafter.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        Non-Personally Identifiable Information :
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          “Non-Personally Identifiable Information” is just the
                          opposite of “Personally Identifiable Information” and
                          does not uniquely identify a unique End User. This
                          includes some information that may be collected
                          automatically and do not require you to submit such
                          data.
                        </p>
                        <p>
                          Your IP address, the URL that you just came from and
                          the URL you are next to visit are some examples of
                          such information.
                        </p>
                        <p>
                          Your IP address, the URL that you just came from and
                          the URL you are next to visit are some examples of
                          such information.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        SHARING OF PERSONAL INFORMATION :
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          All visitors / Members of this application understand
                          that this application does not sell, rent, or loan any
                          identifiable information at the individual level
                          regarding its customers to any third party. This
                          information is shared only with designated
                          distributors to fulfill your orders.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        DATA SECURITY :
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          We take appropriate steps to protect the personal
                          information you share with us. We have implemented
                          reasonable technology and security features to
                          safeguard the privacy of your personal information.
                        </p>
                        <p>
                          However, you must appreciate and understand that
                          complete confidentiality and security are not yet
                          possible over the Internet, and privacy cannot be
                          assured in your communications to us. You acknowledge
                          that personal information is disclosed at your own
                          risk, and may be subject to unauthorized use by
                          others. This may result in you receiving unsolicited
                          messages from other parties.
                        </p>
                        <p>
                          We are and will not be responsible in any manner for
                          direct, indirect, special or consequential damages,
                          howsoever caused arising out of the communication of
                          information to us.
                        </p>
                        <p>
                          We will permit only authorized employees, who are
                          trained in the proper handling of customer
                          information, to have access to that information.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        RELEASE OF NON-PERSONALLY IDENTIFIABLE INFORMATION:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          We may share or disclose Non-Personally Identifiable
                          Information to our Partners, Affiliates and
                          Distributors. We may share aggregated demographic
                          information (which does not include any Personally
                          Identifiable Information) with designated
                          distributors.
                        </p>
                        <p>
                          We also use Third Party Service Providers to track and
                          analyze Non-Personally Identifiable usage and volume
                          statistical information from our Users to administer
                          our Application and constantly improve its quality.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        UPDATING PERSONALLY IDENTIFIABLE INFORMATION:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          All users have the ability to access, update and
                          delete their Personally Identifiable Information in
                          our application. In fact, we always advise our users
                          to update their Personally Identifiable Information
                          from time to time. To update your information follow
                          the steps below:
                        </p>
                        <ul>
                          <li>Login to your account</li>
                          <li>Click on 'My Account'</li>
                          <li>
                            On the Dashboard click on ‘Update your Profile’ and
                            make the necessary changes.
                          </li>
                        </ul>
                        If you would like to have some or all of your Personally
                        Identifiable Information to be removed from our
                        databases, please contact us via e-mail at
                        grihanirmanguide@gmail.com
                        <p>
                          We will do the needful to remove your Personally
                          Identifiable Information from our files.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        DATA TRACKING:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          "Cookies" are certain data collection devices placed
                          on your hard drive, which we use on certain pages of
                          the Application, GNG to assist us analyze our webpage
                          flow, measure promotional effectiveness and promote
                          trust and ensure safety. Most cookies are "session
                          cookies" meaning that they automatically get deleted
                          from your hard drive at the end of a session, (that
                          is, when you sign out from GNG or close your web
                          browser). You are always free to decline our cookies
                          if your browser permits, although, in that case, you
                          may not be able to use certain features on the
                          application.
                        </p>
                        <p>Some of the features which cookies support:</p>
                        <ul>
                          <li>
                            {" "}
                            Measuring the effectiveness of our sites, services,
                            content and advertising{" "}
                          </li>{" "}
                          <li>
                            {" "}
                            Allowing you to enter your password less frequently
                            during a session.{" "}
                          </li>{" "}
                          <li>
                            Remembering your preferences, buying behavior and
                            presenting information that are targeted to your
                            interests to make your experience more personalized{" "}
                          </li>
                          <li>
                            {" "}
                            Providing other services and features that are
                            available only through the use of cookies{" "}
                          </li>
                          <li>
                            {" "}
                            If you choose to post messages on our message
                            boards, chat rooms or other message areas or leave a
                            feedback, we will collect that information from you.
                            We retain this information as necessary to resolve
                            disputes, provide customer support and troubleshoot
                            problems as permitted by law.{" "}
                          </li>{" "}
                          <li>
                            {" "}
                            If you send us personal correspondence such as
                            emails or letters, or if other users or third
                            parties send us correspondence about your activities
                            or postings on the application, we may collect such
                            information and maintain a file specific to you.
                          </li>
                        </ul>
                        Additionally, you may encounter "cookies" or other
                        similar devices on certain pages of the Application,
                        that are placed by third parties.
                        <p>
                          WE DO NOT CONTROL THE USE OF COOKIES BY THIRD PARTIES.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        SECURITY OF PERSONALLY IDENTIFIABLE INFORMATION:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          GNG has rigorous security measures in place to protect
                          the loss, misuse and modification of your Personally
                          Identifiable Information. We always provide you the
                          use of a secure server that only selected personnel
                          and contractors can access and thus, track all your
                          account accesses and modifications.
                        </p>
                        <p>
                          However, no data transmission over the Internet is
                          absolutely safe and secure. Accordingly, we cannot
                          ensure or warrant the security of any information that
                          you transmit to us. You transmit any information over
                          the Internet solely at your own risk.
                        </p>
                        <p>
                          We would also like to request you to keep your user
                          name, password and other personal information
                          confidential to protect your account against
                          unauthorized access.
                        </p>
                        <p>
                          Always remember, GNG customer service representatives
                          will never request you for your password.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        OTHER CONTENT:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          The Services may include hyperlinks to other
                          applications or content or resources. The application
                          may have no control over other applications or
                          resources, which are provided by companies or persons
                          other than this application.
                        </p>
                        <p>
                          You acknowledge and agree that this application is not
                          responsible for the availability of any such external
                          sites or resources, and does not endorse any
                          advertising, products or other materials on or
                          available from such web sites or resources.
                        </p>
                        <p>
                          You acknowledge and agree that this application is not
                          liable for any loss or damage which may be incurred by
                          you as a result of the availability of those external
                          applications or resources, or as a result of any
                          reliance placed by you on the completeness, accuracy
                          or existence of any advertising, products or other
                          materials on, or available from, such web sites or
                          resources.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        MISCELLANEOUS PRIVACY POLICY:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          As mention in our Terms & Conditions, no one under age
                          13 are permitted to register at or use the features of
                          GNG We do not intent to sell our services and products
                          to them, neither do we wish to collect or maintain
                          information from anyone known to be under 13. No part
                          of the application, GNG is designed to attract anyone
                          under the age of 13. If you are a minor but still want
                          to purchase a product, you may do so through a parent
                          or guardian who is a member of GNG
                        </p>
                        <p>
                          Protecting the privacy of children is our primary
                          concern. Hence, we will not knowingly collect or
                          maintain Data on our application from those who are
                          under 13 years of age.
                        </p>
                        <p>
                          Protecting the privacy of children is our primary
                          concern. Hence, we will not knowingly collect or
                          maintain Data on our application from those who are
                          under 13 years of age.
                        </p>
                        <p>Mindfulness and compliance with company policies</p>
                        <p>
                          Our e-mail distributions will be in compliance with
                          our current Privacy Policy. You may receive
                          information about various products, services,
                          promotional and special offers from us via email. We
                          may link the data you give us about yourself with any
                          data we have gathered (via cookies) about your use of
                          this application, so that we can send you information
                          about various products and services, offered on this
                          application, which you may find interesting.
                        </p>
                        <p>
                          Public Areas
                          <br /> Certain areas on our application, GNG allow you
                          to publicly post feedbacks on GNG. When you
                          communicate through such platforms you must understand
                          that such information is public and may be accessible
                          by other consumers and companies as well. In fact, the
                          information may appear on other applications or web
                          searches, making it easier for others to collect, read
                          or use your information.
                        </p>
                        <p>
                          Public Areas Certain areas on our application, GNG
                          allow you to publicly post feedbacks on GNG. When you
                          communicate through such platforms you must understand
                          that such information is public and may be accessible
                          by other consumers and companies as well. In fact, the
                          information may appear on other applications or web
                          searches, making it easier for others to collect, read
                          or use your information.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        PRECAUTIONS YOU CAN TAKE:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          We advice that you take the following precautions to
                          ensure the safety of your data:
                        </p>
                        <ul>
                          <li>
                            Use a complex password and do not disclose it to
                            anyone. Change it frequently and never write it
                            down. Remember, you will be responsible for all
                            actions taken in the name of your account.
                          </li>
                          <li>Keep your computer safe by using up-to-date</li>
                          <li>
                            Keep your computer safe by using up-to-date
                            anti-virus security software and the latest browser.
                          </li>
                          <li>
                            Be aware of increasingly common phishing mails that
                            use your email address to contact you and ask you
                            for personal or sensitive information. Also know
                            that GNG and its representatives will not send you
                            emails asking for personal or sensitive information.
                            So if ever asked to provide such information, you
                            can be certain that it is not from us.
                          </li>
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      //   expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}>
                        OTHERS:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontSize: "15px" }}>
                        <p>
                          You recognize and understand that there is no
                          compulsion on you to provide us with your personal
                          information and any and all personal information
                          provided by you to us is with your full consent, own
                          volition and desire to provide such personal
                          information. You also understand that we are under no
                          obligation to verify the source from which the
                          personal information about you is provided to us, and
                          they are deemed to be provided by you, unless you
                          demonstrate to us within a period of fifteen days from
                          the date of providing of such information to us, to
                          our satisfaction, that the information was provided to
                          us without your free consent.
                        </p>

                        <p>
                          We may use technology or other means to protect the
                          service, protect our customers, or stop you from
                          breaching this contract. These means may include, for
                          example, filtering to stop spam or increase security.
                          These means may hinder or break your use of the
                          service.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="question-form">
                <h1>Ask Your Questions</h1>
                <form action="#">
                  <div className="row">
                    <div className="col-md-12">
                      <input type="text" name="name" placeholder="Name*" />
                    </div>
                    <div className="col-md-12">
                      <input type="text" name="email" placeholder="Email*" />
                    </div>
                    <div className="col-md-12">
                      <input type="text" name="subject" placeholder="Subject" />
                    </div>
                    <div className="col-md-12">
                      <textarea
                        name="question"
                        placeholder="Your Questions..."
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button className="thm-btn" type="submit">
                        Submit Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
