import React from 'react'
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function GreadLoader() {
    window.scrollTo(0, 0);

    return (
        <div>
              {[1,2,3,4].map((obj, i) =>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 text-center">
                                <div className="single-price-box">
                                  <Skeleton  height={250} /> 
                                </div>
                            </div>
               )}     
            
        </div>
    )
}
