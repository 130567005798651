import React, { Component } from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Link, withRouter } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator';
import * as CONSTANT from '../../utils/constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import BackDrop1 from '../Loader/BackDrop1';
import { Button } from '@material-ui/core';
import { checkAuth } from '../../utils/auth';

export default class TopArea extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            name: '',
            email: '',
            phone: '',
            loading: false,
        }
        this.validator = new SimpleReactValidator({
            messages: {
                in: 'Passwords need to match!'
            }
        })
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                open: true

            })
        }, 20000);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        var formdata = new FormData(e.target);
        if (this.validator.allValid()) {
            authformpost('/api/intarest', formdata)
                .then((data) => {
                    console.log(data.data)
                    toast.dark("Quotation add successfully", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 3000,
                    });
                    this.setState({ loading: false, open: false })

                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    onCloseModal = () => {
        this.setState({
            open: false

        })
    }
    onOpenModal = () => {
        this.setState({
            open: true

        })
    }

    render() {
        return (

            <div className="topheader">
                {this.state.loading ?
                    <BackDrop1 /> : null}
                <Modal open={this.state.open} onClose={this.onCloseModal} center
                    classNames={{
                        modal: 'customModal',
                    }}
                >
                    <h2 style={{ paddingLeft: "10px" }}>Get Free Quotation </h2><hr />
                    <div className="card ">
                        <div className="card-body">

                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Name</label>
                                    <input type="text" className="form-control" id="name" name="name" aria-describedby="emailHelp" placeholder="Enter Name" value={this.state.name} onChange={this.handleChange} />
                                    {this.validator.message('name', this.state.name, 'required', { className: 'text-danger' })}
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputPassword1">Phone No</label>
                                    <input type="number" className="form-control" id="phone" name="phone" placeholder="Enter Phone Number" value={this.state.phone} onChange={this.handleChange} />
                                    {this.validator.message('phone', this.state.phone, 'required', { className: 'text-danger' })}
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputPassword1">Email Id (Optional)</label>
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} />
                                </div>

                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>


                        </div>
                    </div>


                </Modal>


                <section className="top-bar-area topheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3" style={{ color: "white", fontSize: "16px", fontWeight: "bold" }}>
                                support@grihanirmanguide.com
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <button className="btn btn-success" onClick={this.onOpenModal}> Get Free Quotation</button>
                            </div>




                            <div className="col-lg-5 col-md-5 row">
                                <div className="col-lg-5 col-md-5">


                                    <div style={{ color: "white", fontSize: "16px", fontWeight: "bold" }}>
                                        <p><span className=""></span>‎+91 85879 97907</p>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7">


                                    {!checkAuth.isAuthenticated ?
                                        <Link to="login" className="btn btn-success" style={{ padding: "4px 15px 4px 15px" }}> Login</Link>
                                        :
                                        <b className="btn btn-default" style={{ width: "80%" }}>{localStorage.getItem('userName')}</b>
                                    }
                                </div>

                            </div>

                            <div className="col-lg-1 col-md-1" style={{ display: "flex", justifyContent: "space-between" }}>

                                <a href="https://www.facebook.com/GrihaNirmanGuide" target="_blank"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                <a href="https://instagram.com/griha_nirman_guide?utm_medium=copy_link" target="_blank"><i className="fab fa-instagram"></i></a>


                            </div>


                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
