import React from 'react'

export default function Faq() {
    window.scrollTo(0, 0);

    return (
        <div>
            <section className="faq-content-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="accordion-box">
                                <div className="accordion accordion-block">
                                    <div className="accord-btn"><h4>Do you do the design and the execution yourselves?</h4></div>
                                    <div className="accord-content">
                                        <p>We give a Guarantee for a Period of 5 years and promise to rectify any fault arising out of faulty workmanship at our cost. However the guarantee does not hold good for mishandling and breakable items.</p>
                                    </div>
                                </div>

                                <div className="accordion accordion-block">
                                    <div className="accord-btn active"><h4>Do you give Guarantee and After sales service?</h4></div>
                                    <div className="accord-content collapsed">
                                        <p>We give a Guarantee for a Period of 5 years and promise to rectify any fault arising out of faulty workmanship at our cost. However the guarantee does not hold good for mishandling and breakable items.</p>
                                    </div>
                                </div>

                                <div className="accordion accordion-block">
                                    <div className="accord-btn"><h4>What are the factors affecting the cost?</h4></div>
                                    <div className="accord-content">
                                        <p>We give a Guarantee for a Period of 5 years and promise to rectify any fault arising out of faulty workmanship at our cost. However the guarantee does not hold good for mishandling and breakable items.</p>
                                    </div>
                                </div>

                                <div className="accordion accordion-block">
                                    <div className="accord-btn"><h4>Will you be able to give a quote, if given the floor plan?</h4></div>
                                    <div className="accord-content">
                                        <p>We give a Guarantee for a Period of 5 years and promise to rectify any fault arising out of faulty workmanship at our cost. However the guarantee does not hold good for mishandling and breakable items.</p>
                                    </div>
                                </div>

                                <div className="accordion accordion-block">
                                    <div className="accord-btn"><h4>At what stage an interior designing work could be started?</h4></div>
                                    <div className="accord-content">
                                        <p>We give a Guarantee for a Period of 5 years and promise to rectify any fault arising out of faulty workmanship at our cost. However the guarantee does not hold good for mishandling and breakable items.</p>
                                    </div>
                                </div>

                                <div className="accordion accordion-block">
                                    <div className="accord-btn"><h4>Do you charge for giving a Proposal?</h4></div>
                                    <div className="accord-content">
                                        <p>We give a Guarantee for a Period of 5 years and promise to rectify any fault arising out of faulty workmanship at our cost. However the guarantee does not hold good for mishandling and breakable items.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="question-form">
                                <h1>Ask Your Questions</h1>
                                <form action="#">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input type="text" name="name" placeholder="Name*" />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="text" name="email" placeholder="Email*" />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="text" name="subject" placeholder="Subject" />
                                        </div>
                                        <div className="col-md-12">
                                            <textarea name="question" placeholder="Your Questions..."></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="thm-btn" type="submit">Submit Now</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
