import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, withRouter } from 'react-router-dom'
import * as CONSTANT from '../../utils/constant';
import { checkAuth } from '../../utils/auth';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ display: "block", position: "absolute", right: "23px", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}
            onClick={onClick}
        >
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick} style={{ display: "block", position: "absolute", left: "23px", zIndex: "10", backgroundColor: "rgb(255 255 255 / 66%)", height: "auto", width: "auto", padding: "10px" }}>
        </div>
    );
}

export default class LatestProjectArea extends Component {
    constructor(props) {
        super(props)

        this.state = {
            service: [],
        }
    }
    componentDidMount() {
        get(`/api/showService`)
            .then((response) => {
                const sd = response.data.service.filter(gs => gs.service_status == 1)
                this.setState({
                    service: sd
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    render() {
        var settings = {
            dots: true,
            className: "center",
            centerMode: true,
            infinite: false,
            centerPadding: "60px",
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: true,
                        autoplay: true,
                        speed: 500,
                        autoplaySpeed: 3000,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        autoplay: true,
                        speed: 500,
                        autoplaySpeed: 3000,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                        speed: 500,
                        autoplaySpeed: 3000,
                    }
                }
            ]
        };

        return (
            <div>


                <section className="working-area">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2>WHY CHOOSE GRIHANIRMANGUIDE</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-6 col-xs-12 col-6">
                                <div className="single-working-item">


                                    <div className="icon-box">
                                            <img src="image_gg/why_choose _us_logo/extensive_experience.jpg" className="image_redious"/>
                                    </div>

                                    <div className="text-box text-center">
                                        <h4 style={{color:"black"}}>Extensive experience </h4>
                                        <p className="fonn_sizell" style={{color:"black"}}>We bring unique simplistic designs and help build your imagination into existence.</p>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-xs-12 col-6">
                                <div className="single-working-item">
                                    <div className="icon-box">
                                            <img src="image_gg/why_choose _us_logo/creative_designers.jpg" className="image_redious"/>
                                    </div>
                                    <div className="text-box text-center">
                                        <h4 style={{color:"black"}}>Creative designers </h4>
                                        <p className="fonn_sizell" style={{color:"black"}}>Our team helps build your dream house with your desires and resembles your identity into form and volume. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-xs-12 col-6">
                                <div className="single-working-item">

                                    <div className="icon-box">
                                            <img src="image_gg/why_choose _us_logo/star_rated_product.jpg" className="image_redious"/>
                                    </div>
                                    <div className="text-box text-center">
                                        <h4 style={{color:"black"}}>Star rated products</h4>
                                        <p className="fonn_sizell" style={{color:"black"}}>We accommodate optimum quality products for our clients.</p>

                                    </div>
                                </div>
                            </div>



                            <div className="col-md-4 col-sm-6 col-xs-12 col-6">
                                <div className="single-working-item">


                                    <div className="icon-box">
                                            <img src="image_gg/why_choose _us_logo/dedicated_team.jpg" className="image_redious"/>
                                    </div>
                                    <div className="text-box text-center">
                                        <h4 style={{color:"black"}}>Dedicated Team</h4>
                                        <p className="fonn_sizell" style={{color:"black"}}>Our team ensures best experience for clients with utmost sincerity.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 col-6">
                                <div className="single-working-item">


                                    <div className="icon-box">
                                            <img src="image_gg/why_choose _us_logo/free_consultation.jpg" className="image_redious"/>
                                    </div>
                                    <div className="text-box text-center">
                                        <h4 style={{color:"black"}}>Free Consultation </h4>
                                        <p className="fonn_sizell" style={{color:"black"}}>We provide our clients with free consultation and guidance for designing.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 col-6" >
                                <div className="single-working-item">

                                    <div className="icon-box">
                                            <img src="image_gg/why_choose _us_logo/end_to_send_service.jpg" className="image_redious"/>
                                    </div> 
                                    <div className="text-box text-center">
                                        <h4 style={{color:"black"}}>End to End Service</h4>

                                        <p className="fonn_sizell" style={{color:"black"}}>We are always present to guide our clients wherever and whenever you need.</p>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
