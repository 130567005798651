import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import ReactPlayer from "react-player";
import "./Player.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {
  get,
  authput,
  authget,
  post,
  authformpost,
  authdelete,
} from "../../utils/service";
import * as CONSTANT from "../../utils/constant";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        display: "block",
        position: "absolute",
        right: "23px",
        backgroundColor: "rgb(255 255 255 / 66%)",
        height: "auto",
        width: "auto",
        padding: "10px",
      }}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        display: "block",
        position: "absolute",
        left: "23px",
        zIndex: "10",
        backgroundColor: "rgb(255 255 255 / 66%)",
        height: "auto",
        width: "auto",
        padding: "10px",
      }}
    ></div>
  );
}
export default class WelcomeArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      url: "",
      video: [],
    };
  }
  componentDidMount() {
    get(`/api/viewVideo`)
      .then((response) => {
        this.setState({
          video: response.data.video,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  onCloseModal = () => {
    this.setState({
      open: false,
    });
  };

  onOpenModal = (data1) => {
    console.log(data1);
    this.setState({
      open: true,
      url: data1,
    });
  };
  render() {
    var settings = {
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 1,
      className: "center",
      infinite: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Modal open={this.state.open} onClose={this.onCloseModal} center>
          <h4>Video</h4>
          <hr></hr>
          <div className="player-wrapper">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=-X4ikwUwxoE"
              className="react-player"
              playing
              width="100%"
              height="100%"
              controls={true}
            />
          </div>
        </Modal>
        <section className="welcome-area">
          <div className="container clearfix">
            <div className="sec-title">
              <h2>Building knowledge centre</h2>
              <span className="decor"></span>
            </div>
            <Slider {...settings}>
              {this.state.video.map((object, i) => (
                <div className="col-md-3 col-sm-6 col-xs-12 single-project filter-item industrial spa">
                  <div className="single-project-item">
                    <div className="img-holder">
                      <div className="player-wrapper">
                        <ReactPlayer
                          url={object.video_file}
                          className="react-player"
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      </div>
                      <div className="content">
                        <a href="javascript:void(0)">
                          <h3>{object.video_name}</h3>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </div>
    );
  }
}
