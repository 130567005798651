import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackDrop1 from '../Loader/BackDrop1';
toast.configure();

export default class Login extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        this.state = {
            phone: '',
            password: '',
            loading: false,
            password_type: 'password'
        };
        this.validator = new SimpleReactValidator();
    }
    UNSAFE_componentWillMount() {
        if (checkAuth.isAuthenticated)
            this.props.history.push('/')
    }
    componentDidMount(prevProps) {
        window.scrollTo(0, 0);
    }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.setState({ loading: true });

        const data = {
            phone: this.state.phone,
            password: this.state.password,
            role_id: 2
        }
        if (this.validator.allValid()) {
            post('/api/userLogin', data)
                .then((data) => {
                    if (data) {


                        if (data.data.error === 'Unauthorised') {
                            toast.dark("invalid credentials!",
                                { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                            this.setState({ loading: false });
                        } else {
                            toast.dark("Login Successfully",
                                { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                            checkAuth.authenticate();
                            localStorage.setItem('userToken', data.data.response.token);
                            localStorage.setItem('userName', data.data.response.user.name);
                            localStorage.setItem('user_id', data.data.response.user.id);
                            localStorage.setItem('userEmail', data.data.response.user.email);
                            localStorage.setItem('userPhone', data.data.response.user.phone);
                            this.props.history.push('/');
                            window.location.reload();
                        }
                    }
                    else {
                        this.setState({ loading: false });

                    }
                }).catch((error) => {
                    console.log(error);
                    this.setState({ loading: false });

                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });

        }
    }

    ll = () => {
        if (this.state.password_type === 'password') {
            this.setState({ password_type: 'text' })
        } else {
            this.setState({ password_type: 'password' })
        }
    }


    render() {
        return (
            <div>
                {this.state.loading ?
                    <BackDrop1 /> : null}
                <section className="account-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="form login-form">
                                    <div className="sec-title-two">
                                        <h3>Login Now</h3>
                                    </div>
                                    <form >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="field-label">Phone Number</div>
                                                <div className="field-input">
                                                    <input className="form-control" type="number" name="phone" placeholder="Phone Number" onChange={this.handleChange} value={this.state.email} style={{ "height": "43px" }} />
                                                    {this.validator.message('phone', this.state.phone, 'required', { className: 'text-danger' })}

                                                    <div className="icon-holder">
                                                        <span className="flaticon-user-silhouette"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="field-label">Password</div>
                                                <div className="field-input">
                                                    <input className="form-control" type={`${this.state.password_type}`} name="password" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" style={{ "height": "43px" }} onChange={this.handleChange} value={this.state.password} />
                                                    <div className="icon-holder">
                                                        {this.state.password_type == 'password' ?
                                                            <i className="fas fa-eye-slash" onClick={this.ll} style={{ cursor: "pointer" }}></i>
                                                            :
                                                            <i className="fas fa-eye" onClick={this.ll} style={{ cursor: "pointer" }}></i>
                                                        }
                                                    </div>
                                                    {this.validator.message('password', this.state.password, 'required', { className: 'text-danger' })}
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">

                                                <div className="forgot-password pull-right">
                                                    <Link to="forgetpassword">Forgot Password?</Link>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <button className="thm-btn bg-cl-1" type="submit" onClick={this.handleSubmit} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Login'}</button>

                                                <div className="forgot-password pull-right">
                                                    <Link to="/register">Register</Link>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        )
    }
}
