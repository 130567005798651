import React, { Component } from 'react'
import queryString from 'query-string';
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'
import * as CONSTANT from '../../utils/constant';
import { Link, withRouter } from 'react-router-dom'
import ReactPlayer from "react-player";
import ShopLoader from '../Loader/ShopLoader';

export default class Library extends Component {
    constructor(props) {
        super(props)
        let param = props.location.search;
        const parsed = queryString.parse(param);
        console.log(parsed)
        this.state = {
            library_cat_id: parsed.id,
            library: [],
            loading: false,
        }
    window.scrollTo(0, 0);

    }

    componentDidMount() {
       this.abc();
       
    }

    abc = ()=>{
        this.setState({
            loading: true,
        })
       
        authget(`/api/showLibrary`)
        .then((response) => {
            const ks = response.data.allLibrary.filter(gs => gs.laibrary_cat_id == this.state.library_cat_id)
            this.setState({
                library: ks,
                loading: false,
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
            let param = this.props.location.search;
            const parsed = queryString.parse(param);
            this.setState({
                library_cat_id: parsed.id,
                library: [],
                loading: false,
            })
            setTimeout(() => {
                this.abc();
            }, 500);
        }
    }


    render() {
        return (
            <div>
                {this.state.loading ?
                    <ShopLoader /> :
                    this.state.library.length !== 0?
                    <section className="shop-area">
                        <div className="container">
                            <div className="row">
                                {this.state.library.map((object, i) =>
                                    <div className="col-md-3 col-sm-4 col-xs-12">
                                        <div className="single-shop-item">
                                            <div className="img-holder">

                                                {object.librarytype == 'Pdf' ?

                                                    <a href={`${CONSTANT.URL}/storage/${object.pdf}`} target="_blank"> <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" alt="Awesome Image" style={{ height: "200px" }} /></a>
                                                    :
                                                    <div className="player-wrapper">
                                                        <ReactPlayer
                                                            url={object.pdf}
                                                            className="react-player"
                                                            width="100%"
                                                            height="100%"
                                                            controls={true}
                                                        />
                                                    </div>
                                                }
                                                {/* <div className="overlay">
                                            <div className="box">
                                                <div className="content">
                                                    <a href="shop-single.html">Add to Cart</a>
                                                </div>
                                            </div>
                                        </div> */}
                                            </div>
                                            <div className="title-holder">
                                                <div className="top clearfix">
                                                    <div className="product-title pull-left">
                                                        <h5>{object.laibrary_name}</h5>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                )}
                             
                            </div>
                           </div>
                    </section>
                   :
                   <>
                   <br/> <br/> <br/> <br/>
                   <center><h1>Laibrary Not Found</h1></center>   <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> 
                   </>
            }


            </div>
        )
    }
}
