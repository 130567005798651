import React from 'react'
import Skeleton from "react-loading-skeleton";

export default function ShopLoader() {
    window.scrollTo(0, 0);

    return (
        <div>
            
            <section className="shop-area">
                    <div className="container">
                        {/* <div className="row showing-result-shorting">
                            <div className="col-md-12">
                                <div className="showing pull-left">
                                         <Skeleton  height={250} /> 
                                </div>
                                <div className="shorting pull-right">
                                          <Skeleton  height={250} /> 
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            {[1,2,3,4,5,6,,1,2].map((obj, i) =>
                                <div className="col-md-3 col-sm-4 col-xs-12">
                                    <div className="single-shop-item">
                                        <div className="img-holder">
                                           
                                          <Skeleton  height={250} /> 
                                                      
                                            {/* <div className="overlay">
                                                <div className="box">
                                                    <div className="content">
                                                          <Skeleton  height={250} /> 

                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                       
                                    </div>
                                </div>
                            )}
                         
                        </div>
                    </div>
                </section>


        </div>
    )
}
