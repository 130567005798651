import React, { Component } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import { authget, get } from '../../utils/service'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import * as CONSTANT from '../../utils/constant';

export default class Order extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0);

        this.state = {
            open: false,
            open1: false,
            enquery: [],
            serviceImage: [],
            ServiceDetails: [],
        }
    }
    componentDidMount() {
        authget(`/api/showEnquery`)
            .then((response) => {
                console.log("sdfjh",response.data)
                console.log("sdfjhkjjh",localStorage.getItem('user_id'))
                console.log(response.data.data)


                const order = response.data.data.filter(gs=>gs.user_id == localStorage.getItem('user_id'))
                

                this.setState({
                    enquery: order,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
        get('/api/showServiceImage')
            .then((response) => {
                console.log(response.data)
                this.setState({
                    serviceImage: response.data.serviceimage
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onCloseModal = () => {
        this.setState({
            open: false,
        })

    }
    onCloseModal1 = () => {
        this.setState({
            open1: false,
        })

    }

    render() {
        return (
            <div>
                <Modal open={this.state.open} onClose={this.onCloseModal} center
                 classNames={{
                    modal: 'customModal1',
                }}
                >
                    <h2 style={{ paddingLeft: "10px" }}>Details </h2><hr />

                    <table class="table table-dark">
                        <tbody>

                            <tr>
                                <th scope="row">Message</th>
                                <td>{this.state.ServiceDetails.client_msg}</td>
                            </tr>
                            <tr>
                                <th scope="row">ORDER ID</th>
                                <td>{this.state.ServiceDetails.order_id}</td>
                            </tr>
                            <tr>
                                <th scope="row">Service Price</th>
                                <td>{this.state.ServiceDetails.service_price}</td>
                            </tr>
                            <tr>
                                <th scope="row">Coupon Price</th>
                                <td>{this.state.ServiceDetails.service_price - this.state.ServiceDetails.with_coupon_price}</td>
                            </tr>
                            <tr>
                                <th scope="row">Paybale Price</th>
                                <td>{this.state.ServiceDetails.with_coupon_price}</td>
                            </tr>

                        </tbody>
                    </table>
                </Modal>

                <Modal open={this.state.open1} onClose={this.onCloseModal1} center
                 classNames={{
                    modal: 'customModal1',
                }}
                >
                    <h2 style={{ paddingLeft: "10px" }}>Traking </h2><hr />
                    <div className="card">
                        <div className="card-body">
                            <ul id="progress">
                                <li><div class="node green"></div><p>The First Thing!</p></li>
                                <li><div class="divider green"></div></li>
                                <li><div class="node green"></div><p>The Second Thing!</p></li>
                                <li><div class="divider grey"></div></li>
                                <li><div class="node grey"></div><p>The Third Thing!</p></li>
                                <li><div class="divider grey"></div></li>
                                <li><div class="node grey"></div><p>The Fourth Thing!</p></li>
                                <li><div class="divider grey"></div></li>
                                <li><div class="node grey"></div><p>The Last Thing!</p></li>
                            </ul>
                        </div>
                    </div>

                </Modal>
                <section id="shopping-cart-area" className="checkout-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="shopping-cart-table">
                                    <div className="table">
                                        <table className="cart-table">
                                            <thead className="cart-header">
                                                <tr>
                                                    <th className="product-column">Your Service</th>
                                                    <th>&nbsp;</th>
                                                    <th>Price</th>
                                                    <th>Details</th>
                                                    <th className="price">Traking</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.enquery.map((obj, i) =>
                                                    <tr>
                                                        <td colspan="2" className="product-column">
                                                            <div className="column-box">
                                                                <div className="prod-thumb">
                                                                    {this.state.serviceImage.filter(gs => gs.service_id == obj.service_id).map((object, i) =>

                                                                        i == 0 ?
                                                                            <>
                                                                                <img src={`${CONSTANT.URL}/storage/${object.service_icon}`} alt="Awesome Image" key={i} />
                                                                            </> : null
                                                                    )}
                                                                </div>
                                                                <div className="product-title">
                                                                    <h3>{obj.get_service.service_name}</h3>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="single-price">
                                                            <h5>{obj.service_price}</h5>
                                                        </td>

                                                        <td className="price"><button className="btn btn-success" onClick={() => this.setState({ open: true, ServiceDetails: obj })}>View Details</button></td>
                                                        <td>
                                                            <button className="btn btn-success" onClick={() => this.setState({ open1: true })}>Traking</button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
