import React, { Component } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { checkAuth } from "../../utils/auth";
import {
  authput,
  authget,
  post,
  authformpost,
  authdelete,
  get,
} from "../../utils/service";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import * as CONSTANT from "../../utils/constant";
import { Modal } from "react-responsive-modal";
import queryString from "query-string";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { addToCart } from "../../services/Actions/actions.js";
import { connect } from "react-redux";
import ProductDetailsLodar from "../Loader/ProductDetailsLodar";
toast.configure();

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        display: "block",
        position: "absolute",
        right: "23px",
        backgroundColor: "rgb(255 255 255 / 66%)",
        height: "auto",
        width: "auto",
        padding: "10px",
      }}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        display: "block",
        position: "absolute",
        left: "23px",
        zIndex: "10",
        backgroundColor: "rgb(255 255 255 / 66%)",
        height: "auto",
        width: "auto",
        padding: "10px",
      }}
    ></div>
  );
}

class ProjectDetails extends Component {
  constructor(props) {
    let param = props.location.search;
    const parsed = queryString.parse(param);
    window.scrollTo(0, 0);

    super(props);
    this.state = {
      service_id: parsed.id,
      service: [],
      serviceImage: [],
      serviceFeature: [],
      cat_name: "",
      cat_id: "",
      subcat_name: "",
      relatedService: [],
      loading: false,
    };
  }
  getservice = () => {
    get(`/api/showIndivisualService?service_id=${this.state.service_id}`)
      .then((response) => {
        this.setState({
          service: response.data.service,
          serviceImage: response.data.service.get_serviceimage,
          serviceFeature: response.data.service.get_servicefeature,
          cat_name: response.data.service.get_cat_service.cat_name,
          cat_id: response.data.service.get_cat_service.id,
          subcat_name: response.data.service.get_sub_cat_service.subcat_name,
          loading: true,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getservice();
    get(`/api/showService`)
      .then((response) => {
        this.setState({
          relatedService: response.data.service,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
      this.getservice();
    }
  }
  routeChange = (service_id) => {
    this.props.history.push(`projectdetails?id=${service_id}`);
    this.setState({ service_id });
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 3000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <div>
        {this.state.loading ? (
          <section id="project-single-area" class="project-single-v1-area">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                  <div
                    id="project-single-carousel"
                    class="carousel slide"
                    data-ride="carousel"
                  >
                    <ol class="carousel-indicators">
                      <li
                        data-target="#project-single-carousel"
                        data-slide-to="0"
                        class="active"
                      ></li>
                      <li
                        data-target="#project-single-carousel"
                        data-slide-to="1"
                      ></li>
                      <li
                        data-target="#project-single-carousel"
                        data-slide-to="2"
                      ></li>
                    </ol>
                    <div class="carousel-inner" role="listbox">
                      <Slider {...settings}>
                        {this.state.serviceImage.map((object, i) => (
                          <div class="item{}">
                            <div class="single-item">
                              <div class="img-holder">
                                <img
                                  src={`${CONSTANT.URL}/storage/${object.service_icon}`}
                                  alt="Awesome Image"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                  <div class="project-info">
                    <h3>Project Information</h3>
                    <ul class="project-info-list">
                      <li>
                        <div class="icon-holder">
                          <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <div class="text-holder">
                          <h5> Design Code</h5>
                          <b>{this.state.service.service_name}</b>
                        </div>
                      </li>
                      <li>
                        <div class="icon-holder">
                          <i class="fa fa-rupee" aria-hidden="true"></i>
                        </div>
                        <div class="text-holder">
                          <h5>Design Documantation Price</h5>
                          <b>₹{this.state.service.service_price}</b>
                        </div>
                      </li>
                      <br />
                      <li>
                        <div class="icon-holder">
                          <i class="fa fa-folder-open" aria-hidden="true"></i>
                        </div>
                        <div class="text-holder">
                          <h5>Category</h5>
                          <b>{this.state.cat_name}</b>
                        </div>
                      </li>
                      <li>
                        <div class="icon-holder">
                          <i class="fa fa-folder-open" aria-hidden="true"></i>
                        </div>
                        <div class="text-holder">
                          <h5>
                            SubCategory
                          </h5>
                          <b>{this.state.subcat_name}</b>
                        </div>
                      </li>
                    </ul>
                    <div className="placeorder-button ">
                      <center>
                        {" "}
                        <Link
                          to={`checkout?id=${this.state.service_id}`}
                          className="btn btn-success buttonresize"
                        >
                          Place Order
                        </Link>
                      </center>
                    </div>
                    <br />
                    {!checkAuth.isAuthenticated ? (
                      <div className="placeorder-button ">
                        <center>
                          {" "}
                          <a
                            onClick={() => {
                              toast.dark("Login Your Account", {
                                position: toast.POSITION.BOTTOM_CENTER,
                                autoClose: 3000,
                              });
                            }}
                            className="btn btn-primary buttonresize"
                          >
                            Watch Later
                          </a>
                        </center>
                      </div>
                    ) : (
                      <div className="placeorder-button ">
                        <center>
                          {" "}
                          <a
                            onClick={() =>
                              this.props.adtocart(this.state.service.id)
                            }
                            className="btn btn-primary buttonresize"
                          >
                            Watch Later
                          </a>
                        </center>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="project-description-content">
                    <h3>Design Description</h3>
                    <div class="text">
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.service.service_des,
                          }}
                        ></div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="related-project-items">
                  <div class="sec-title text-center">
                    <h2>Related Service </h2>
                  </div>

                  {this.state.relatedService
                    .filter((gs) => gs.cat_id == this.state.cat_id)
                    .filter((ks) => ks.service_id !== this.state.service_id)
                    .map((object, i) => (
                      <div class="col-md-4" key={i}>
                        <div class="single-project-item">
                          <div class="img-holder">
                            {object.get_serviceimage.length != 0
                              ? object.get_serviceimage.map((obj, i) =>
                                  i === 0 ? (
                                    <img
                                      src={`${CONSTANT.URL}/storage/${obj.service_icon}`}
                                      alt="Awesome Image"
                                      style={{ height: "250px", width: "100%" }}
                                    />
                                  ) : null
                                )
                              : null}
                            <div class="overlay">
                              <div class="box">
                                <div class="content">
                                  <div class="icon-holder">
                                    <a
                                      onClick={() =>
                                        this.routeChange(object.service_id)
                                      }
                                      data-rel="prettyPhoto"
                                      title="Grihanirmanguide"
                                    >
                                      <span class="flaticon-cross"></span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-holder">
                            <a href="javascript:void(0);">
                              <h3>{object.service_name}</h3>
                            </a>
                            <p>₹{object.service_price}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <ProductDetailsLodar />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    catdetails: state.addItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adtocart: (data) => {
      dispatch(addToCart(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
