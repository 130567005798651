import React, { Component } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { authput, authget, post, authformpost, authdelete } from '../../utils/service'
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import * as CONSTANT from '../../utils/constant';
import { Modal } from 'react-responsive-modal';
import queryString from 'query-string';
var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');
toast.configure();
export default class CompleteProjectDetails extends Component {
    constructor(props) {
        let param = props.location.search;
        const parsed = queryString.parse(param);
        var backAgain = urlCrypt.decryptObj(parsed.id);
        super(props)
        this.state = {
            completeImages:backAgain,
        }
        window.scrollTo(0, 0);

    }
    
    render() {
        return (
            <div>
                {/* <section class="breadcrumb-area" style={{"background-image": "url(images/breadcrumb/breadcrumb-bg.jpg)"}}>
                    <div class="container-fluid text-center">
                        <h1>2 Columns Grid</h1>
                        <div class="breadcrumb-bottom">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="left pull-left">
                                            <ul>
                                                <li><a href="index-2.html">Home</a></li>
                                                <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                                <li><a href="project-grid-v1.html">Projects</a></li>
                                                <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                                <li>2 Columns Grid</li>
                                            </ul>
                                        </div>
                                        <div class="right pull-right">
                                            <a href="#"><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Get a Quote</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section id="project-area" class="pro-classic-v1-area">
                    <div class="container">
                        <ul class="project-filter post-filter text-center">
                            <li class="active" data-filter=".filter-item"><span>View All ({this.state.completeImages.length})</span></li>
                        </ul>
                        <div class="row project-content masonary-layout filter-layout">
                            {this.state.completeImages.map((object,i)=>
                            <div class="single-project-item col-md-6 col-sm-6 col-xs-12 filter-item resid commer corp">
                                <div class="img-holder">
                                    <img src={`${CONSTANT.URL}/storage/${object.complete_service_icon}`} alt="Awesome Image" />
                                    <div class="overlay-style-2">
                                        <div class="box">
                                            <div class="content">
                                              
                                                <div class="icon-holder">
                                                    <a href={`${CONSTANT.URL}/storage/${object.complete_service_icon}`} data-rel="prettyPhoto" title="Interrio Project"><i class="fa fa-camera"></i></a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                         </div>
                          </div>
                </section>

            </div>
        )
    }
}
