import React from 'react'
import Skeleton from "react-loading-skeleton";

export default function ProductDetailsLodar() {
    window.scrollTo(0, 0);

    return (
        <div>
            <section id="project-single-area" class="project-single-v1-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <Skeleton height={250} />
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div class="project-info">
                                <Skeleton height={10} />

                                <ul class="project-info-list">
                                    <li>
                                        <Skeleton height={10} />

                                    </li>
                                    <li>
                                        <Skeleton height={10} />

                                    </li>
                                    <br />
                                    <li>
                                        <Skeleton height={10} />

                                    </li>
                                    <li>
                                        <Skeleton height={10} />

                                    </li>
                                </ul>
                                <div className="placeorder-button ">
                                    <Skeleton height={10} />
                                </div><br />
                                <div className="placeorder-button ">
                                    <Skeleton height={10} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="project-description-content">
                            <Skeleton height={10} />

                                <div class="text">
                                    <p>
                                        <Skeleton height={100} />

                                    </p>
                                </div>
                                {/* <div class="project-manager-box">
                                       Features :
                                       <ul>
                                           {this.state.serviceFeature.map((obj,i)=>
                                            <li>
                                                 {obj.feature_name}
                                            </li>
                                           )}
                                       </ul>
                                    </div>
                               */}
                            </div>
                        </div>
                    </div>

                </div>
            </section>


        </div>
    )
}
