import React, { Component } from 'react'
import SubCategory from './Home/SubCategory'
import BrandArea from './Home/BrandArea'
import LatestProjectArea from './Home/LatestProjectArea'
import ServiceArea from './Home/ServiceArea'
import WelcomeArea from './Home/WelcomeArea'
import Feedback from './Home/Feedback'
import queryString from 'query-string';
import Coupon from './Home/Coupon'
var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');

export default class Home2 extends Component {
    constructor(props) {
        super(props)
        let param = props.location.search;
        const parsed = queryString.parse(param);
        const gs =urlCrypt.decryptObj(parsed.cat_id);
        window.scrollTo(0, 0);
        this.state = {
            cat_id:gs,
             
        }
    }
    
    render() {
      
        return (
            <div>
                 <SubCategory cat_id={this.state.cat_id}/>
                 {/* <Coupon/> */}
                 <LatestProjectArea/>
                 <ServiceArea/>
                 {/* <WorkingArea/> */}
                
                 <WelcomeArea/>
                 <Feedback/>
                 <BrandArea/>
            </div>
        )
    }
}
