import React, { Component } from 'react'
import FooterButton from './FooterButton'

export default class Footer extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <a href="https://api.whatsapp.com/send?phone=8170935599&text=Hi" class="whatsapp-button" target="_blank" style={{ "position": "fixed", "right": "15px", "bottom": "15px", zIndex: "100" }}>
                    <img src="https://i.ibb.co/VgSspjY/whatsapp-button.png" alt="botão whatsapp" />
                </a>
                <a class="whatsapp-button" style={{ "position": "fixed", "left": "15px", "bottom": "16px", zIndex: "100" }}>
                    <div style={{ background: "#25D366", color: "white" }}><b style={{ paddingLeft: "10px", paddingRight: "10px" }}> <i className="fa fa-phone"></i> ‎+91 85879 97907</b></div>
                </a>

                <footer className="footer-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="single-footer-widget pd-bottom">
                                    <div className="footer-logo">

                                        <img src="images/footer/logo.jpg" alt="Awesome Footer Logo" />

                                    </div>
                                    <div className="interrio-info">
                                        <p> Griha Nirman Guide brings extensive experience of residential design and interior execution to you. Our design professionals carry out and put your visualization into effect.</p>
                                    </div>
                                    <ul className="footer-contact-info">
                                        <li>
                                            <div className="icon-holder">
                                                <span className="flaticon-building"></span>
                                            </div>
                                            <div className="text-holder">
                                                <p>Plot No. 300, Debhog, City Centre, Haldia, Purba Medinipur, West Bengal – 721657</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon-holder">
                                                <span className="flaticon-technology"></span>
                                            </div>
                                            <div className="text-holder">
                                                <p>‎+91 85879 97907,+91 8170935599</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon-holder">
                                                <span className="flaticon-e-mail-envelope"></span>
                                            </div>
                                            <div className="text-holder">
                                                <p>support@grihanirmanguide.com</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon-holder time">
                                                <span className="flaticon-clock"></span>
                                            </div>
                                            <div className="text-holder">
                                                <p>
                                                    Monday to Saturday 9am to 6pm
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="single-footer-widget pd-bottom">
                                    <div className="title">
                                        <h3>Our Working Process</h3>
                                    </div>
                                    <ul className="popular-news clearfix">
                                        <li className="single-popular-news-item clearfix">
                                            <div className="img-holder">
                                                <img src="image_gg/process_logo/3.jpg" alt="Awesome Image" />

                                                <div className="overlay">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="javascript:void(0);"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <a href="javascript:void(0);">
                                                    <h4>SURVEY</h4>
                                                    <p> Precise capture of structural and aesthetical properties.</p>
                                                </a>

                                            </div>
                                        </li>
                                        <li className="single-popular-news-item">
                                            <div className="img-holder">
                                                <img src="image_gg/process_logo/2.jpg" alt="Awesome Image" />
                                                <div className="overlay">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="javascript:void(0);"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <a href="javascript:void(0);">
                                                    <h4>CONSULT</h4>
                                                    <p>Stream of design ideas to fulfil your dreams and needs.</p>
                                                </a>

                                            </div>
                                        </li>
                                        <li className="single-popular-news-item">
                                            <div className="img-holder">
                                                <img src="image_gg/process_logo/1.jpg" alt="Awesome Image" />

                                                <div className="overlay">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="javascript:void(0);"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <a href="javascript:void(0);">
                                                    <h4>DESIGN AND ESTIMATE</h4>
                                                    <p> Designs to satisfy your desires with budget friendly choices.</p>
                                                </a>

                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="single-footer-widget pd-bottom">
                                    <div className="title futterPading">
                                        {/* <h3>Popular News</h3> */}
                                    </div>
                                    <ul className="popular-news clearfix">
                                        <li className="single-popular-news-item clearfix">

                                            <div className="img-holder">
                                                <img src="image_gg/process_logo/4.jpg" alt="Awesome Image" />
                                                <div className="overlay">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="javascript:void(0);"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <a href="javascript:void(0);">
                                                    <h4>CONSTRUCTION</h4>
                                                    <p>We equip with optimum quality products from sincere and rapid construction of your dream home.</p>
                                                </a>

                                            </div>
                                        </li>
                                        <li className="single-popular-news-item">
                                            <div className="img-holder">
                                                <img src="image_gg/process_logo/5.jpg" alt="Awesome Image" />
                                                <div className="overlay">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="javascript:void(0);"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <a href="javascript:void(0);">
                                                    <h4>MAINTAINENCE</h4>
                                                    <p> Profound preservation of structure as claimed by our clients.</p>
                                                </a>

                                            </div>
                                        </li>
                                        <li className="single-popular-news-item">
                                            <div className="img-holder">
                                                <img src="image_gg/process_logo/6.jpg" alt="Awesome Image" />
                                                <div className="overlay">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="javascript:void(0);"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <a href="javascript:void(0);">
                                                    <h4>SUPPORT</h4>
                                                    <p> We are always present to tend to your necessity without fail.</p>
                                                </a>

                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </footer>
                {/* <FooterButton/> */}
                {/* <div className="scroll-to-top scroll-to-target" data-target="html"><span className="fa fa-angle-up"></span></div> */}
            </div>
        )
    }
}
