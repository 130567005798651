import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { checkAuth } from "../../utils/auth";
import * as CONSTANT from "../../utils/constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  authput,
  authget,
  post,
  authformpost,
  authdelete,
  get,
} from "../../utils/service";
import BackDrop1 from "../Loader/BackDrop1";
import { Link, withRouter } from "react-router-dom";
var settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 1,
  className: "center",
  centerMode: true,
  infinite: false,
  centerPadding: "60px",
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export default function AboutUs() {
  window.scrollTo(0, 0);
  const [open, setopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ratting, setRatting] = useState("");
  const [comment, setComment] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    var formdata = new FormData(e.target);
    authformpost("/api/reviewSubmit", formdata)
      .then((data) => {
        console.log(data.data);
        if (data.data.status === true) {
          toast.dark(data.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.dark(data.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        }
        setLoading(false);
        setopen(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };

  const onCloseModal = () => {
    setopen(false);
  };
  const onOpenModal = () => {
    setopen(true);
  };

  return (
    <div>
      {loading ? <BackDrop1 /> : null}

      <Modal open={open} onClose={onCloseModal} center>
        <h4 style={{ paddingLeft: "10px" }}>Submit Your Review </h4>
        <hr />
        <div className="card wid">
          <div className="card-body pd">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label for="exampleInputEmail1">Rating</label>
                <br />
                <div class="rate">
                  <input
                    type="radio"
                    id="star5"
                    name="reting"
                    value="5"
                    required
                  />
                  <label for="star5" title="text">
                    5 stars
                  </label>
                  <input
                    type="radio"
                    id="star4"
                    name="reting"
                    value="4"
                    required
                  />
                  <label for="star4" title="text">
                    4 stars
                  </label>
                  <input
                    type="radio"
                    id="star3"
                    name="reting"
                    value="3"
                    required
                  />
                  <label for="star3" title="text">
                    3 stars
                  </label>
                  <input
                    type="radio"
                    id="star2"
                    name="reting"
                    value="2"
                    required
                  />
                  <label for="star2" title="text">
                    2 stars
                  </label>
                  <input
                    type="radio"
                    id="star1"
                    name="reting"
                    value="1"
                    required
                  />
                  <label for="star1" title="text">
                    1 star
                  </label>
                </div>
                <br />
              </div>

              <div className="form-group">
                <p style={{ margin: "20px 0 10px" }}>
                  <b>Comment</b>{" "}
                </p>
                <textarea
                  className="form-control"
                  name="comment"
                  id="comment"
                  placeholder="Comment"
                  required
                ></textarea>
              </div>

              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </Modal>

      <section
        className="breadcrumb-area"
        style={{
          "background-image": "url(image_gg/bg_image/about_us_pic.jpg)",
        }}
      >
        <div className="container-fluid text-center">
          {checkAuth.isAuthenticated ? (
            <h1>
              {" "}
              <button className="btn btn-success" onClick={onOpenModal}>
                Review Submit
              </button>
            </h1>
          ) : (
            <h1>About Us</h1>
          )}
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </li>
                      <li>About Us</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-interrio-area">
        <div className="container">
          <div className="sec-title">
            <h2>
              About <span>Griha nirman guide</span>
            </h2>
            <span className="decor"></span>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="text-holder">
                <p style={{ textAlign: "justify" }}>
                  Griha Nirman Guide was established 14 years ago. We are highly
                  experienced in design, construction and services related to
                  residential buildings and interior designing. Griha Nirman
                  Guide, is always thriving to provide a better living
                  experience with optimized budget and balanced quality
                  products. In Griha Nirman Guide, design is beyond the cosmetic
                  and about the soul. So, our mission, is always to fulfil
                  client’s necessities and choices, with utmost sincerity. Griha
                  Nirman Guide leads an experienced team of designers,
                  engineers, supervisors, carpenters, electricians, plumbing,
                  interior designers and contractors. Our process is to
                  understand the design needs and produce fine work through
                  usage of modern software.{" "}
                </p>
              </div>
            </div>
            {/* 
                        <div className="col-md-4">
                            <div className="single-item">
                                <div className="img-holder">
                                    <img src="images/about/mission.jpg" alt="Awesome Image" />
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="content">
                                                <a href="javascript:void(0);"><i className="fa fa-link" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-box">
                                    <h3>Our Mission</h3>
                                    <p>To ensure and achieve our clients’ demands and necessities, and to surpass expectations with regard to organisation, standardization and comfortability. </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="single-item pdtop">
                                <div className="img-holder">
                                    <img src="images/about/vision.jpg" alt="Awesome Image" />
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="content">
                                                <a href="javascript:void(0);"><i className="fa fa-link" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-box">
                                    <h3>Our Vision</h3>
                                    <p>To inevitably provide star rated products with sincere team of designers, to give our clients the best experience.</p>
                                </div>
                            </div>
                        </div>
                   */}
          </div>
        </div>
      </section>

      <section className="team-area">
        <div className="container">
          <div className="sec-title">
            <h2>Our Team</h2>
            <span className="decor"></span>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="team-members">
                {/* <Slider {...settings}> */}
                <div className="col-md-4">
                  <div className="single-team-member">
                    <div className="img-holder">
                      <img
                        src="images\about\Creative_designers_about_us.jpg"
                        alt="Awesome Image"
                      />
                    </div>
                    <div className="text-holder text-center">
                      <h3>Creative designers</h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-team-member">
                    <div className="img-holder">
                      <img
                        src="images\about\skilled_co_workers_about_us.jpg"
                        alt="Awesome Image"
                      />
                    </div>
                    <div className="text-holder text-center">
                      <h3>Skilled co workers</h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-team-member">
                    <div className="img-holder">
                      <img
                        src="images\about\support_team_about _us.jpg"
                        alt="Awesome Image"
                      />
                    </div>
                    <div className="text-holder text-center">
                      <h3>Support team</h3>
                    </div>
                  </div>
                </div>
                {/* </Slider> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
            <section className="slogan-area bg-clr-1" style={{"background-image": "url(images/resources/slogan.jpg)"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="slogan">
                                <h2>We come to you with our large supplier network in the comfort of your own home. No lost weekends,no wonder we’re near by you.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="fact-counter-area">
                <div className="container">
                    <div className="sec-title">
                        <h2>Interesting Facts</h2>
                        <span className="decor"></span>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="fact-counter-carousel">
                                <div className="single-item">
                                    <p>Interrio design involves much more than just a good knowledge of décor. Don’t expect that all decisions on an interior design project would be made by the designer you have hired, See more facts.</p>
                                </div>
                                <div className="single-item">
                                    <p>Interrio design involves much more than just a good knowledge of décor. Don’t expect that all decisions on an interior design project would be made by the designer you have hired, See more facts.</p>
                                </div>
                                <div className="single-item">
                                    <p>Interrio design involves much more than just a good knowledge of décor. Don’t expect that all decisions on an interior design project would be made by the designer you have hired, See more facts.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <div className="single-fact-counter text-center">
                                        <div className="box">
                                            <h2><span className="timer" data-from="1" data-to="5000" data-speed="5000" data-refresh-interval="50">5000</span><i className="fa fa-plus" aria-hidden="true"></i></h2>
                                            <div className="icon-holder">
                                                <span className="flaticon-internet"></span>
                                            </div>
                                        </div>
                                        <div className="title">
                                            <h3>Projects Worked</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <div className="single-fact-counter text-center">
                                        <div className="box">
                                            <h2><span className="timer" data-from="1" data-to="2450" data-speed="5000" data-refresh-interval="50">2450</span></h2>
                                            <div className="icon-holder">
                                                <span className="flaticon-people-1"></span>
                                            </div>
                                        </div>
                                        <div className="title">
                                            <h3>Happy Clients</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <div className="single-fact-counter text-center">
                                        <div className="box">
                                            <h2><span className="timer" data-from="1" data-to="356" data-speed="5000" data-refresh-interval="50">356</span></h2>
                                            <div className="icon-holder">
                                                <span className="flaticon-people"></span>
                                            </div>
                                        </div>
                                        <div className="title">
                                            <h3>Team Members</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <div className="single-fact-counter text-center">
                                        <div className="box">
                                            <h2><span className="timer" data-from="1" data-to="4874" data-speed="5000" data-refresh-interval="50">4874</span></h2>
                                            <div className="icon-holder">
                                                <span className="flaticon-employee"></span>
                                            </div>
                                        </div>
                                        <div className="title">
                                            <h3>Hours of Work</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
}
