import React, { Component } from "react";
import HeaderSearch from "./HeaderSearch";
import TopArea from "./TopArea";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkAuth } from "../../utils/auth";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import SimpleReactValidator from "simple-react-validator";
import BackDrop1 from "../Loader/BackDrop1";
import {
  authput,
  authget,
  post,
  authformpost,
  authdelete,
  get,
} from "../../utils/service";
import { showCart } from "../../services/Actions/actions.js";
import { connect } from "react-redux";

class Header extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      city: [],
      cat: [],
      subcat: [],
      librarycat: [],
      open: false,
      open1: false,
      service_name: "",
      name: localStorage.getItem("userName"),
      email: localStorage.getItem("userEmail"),
      phone: localStorage.getItem("userPhone"),
      user_id: localStorage.getItem("user_id"),
    };
    this.exampleRef = React.createRef();
    this.validator = new SimpleReactValidator({
      messages: {
        in: "Passwords need to match!",
      },
    });
  }
  handleSubmit1 = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var formdata = new FormData(e.target);
    console.log(...formdata);
    if (this.validator.allValid()) {
      // this.setState({ loading: false })
      authformpost("/api/addCustomize", formdata)
        .then((data) => {
          console.log(data.data);
          this.setState({ loading: false, open1: false });
          toast.dark("Booking Successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
          this.abc();
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var formdata = new FormData(e.target);
    if (this.validator.allValid()) {
      authformpost("/api/intarest", formdata)
        .then((data) => {
          console.log(data.data);
          this.setState({ loading: false, open: false });
          toast.dark("Quotation add successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };
  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  searchchange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  search = (e) => {
    e.preventDefault();
    console.log(this.state.service_name);
    this.props.history.push(`/shop?service_name=${this.state.service_name}`);
  };

  componentDidMount() {
    authget(`/api/c`)
      .then((response) => {
        console.log(response.status);
        if (response.status === 401) {
          localStorage.removeItem("userToken");
          localStorage.removeItem("userName");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("userPhone");
          localStorage.removeItem("user_id");
        }
      })
      .catch(function (error) {
        // console.log(error);
      });

    get(`/api/viewCity`)
      .then((response) => {
        console.log(response.data.city);
        const da = response.data.city.filter((gs) => gs.city_status === 1);
        this.setState({
          city: da,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    get(`/api/showCategory`)
      .then((response) => {
        const catd = response.data.data.get_category.filter(
          (gs) => gs.cat_status == 1
        );

        this.setState({
          cat: catd,
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    get(`/api/showLCategory`)
      .then((response) => {
        const catdd = response.data.allLCategory.filter(
          (gs) => gs.laibrary_cat_status == 1
        );

        this.setState({
          librarycat: catdd,
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    get(`/api/showSubcategory`)
      .then((response) => {
        const subcatd = response.data.data.get_subcategory.filter(
          (gs) => gs.subcat_status == 1
        );

        console.log();
        this.setState({
          subcat: subcatd,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    this.props.showCart();
  }

  signout = () => {
    checkAuth.signout();
    localStorage.removeItem("userToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userPhone");
    localStorage.removeItem("user_id");
    window.location.href = "/#/login";
    window.location.reload();
  };
  abc = () => {
    console.log(this.exampleRef.current);
    var x = this.exampleRef.current;
    x.classList.remove("in");
  };

  onCloseModal = () => {
    this.setState({
      open: false,
    });
  };
  onOpenModal = () => {
    this.setState({
      open: true,
    });
  };
  onCloseModal1 = () => {
    this.setState({
      open1: false,
    });
  };
  onOpenModal1 = () => {
    this.setState({
      open1: true,
    });
  };

  imageValid = (e) => {
    // console.log(e.target.id)
    var formData = new FormData();
    var id = e.target.id;
    var file = document.getElementById(id).files[0];
    formData.append("Filedata", file);
    var t = file.type.split("/").pop().toLowerCase();
    if (t != "pdf" && t != "PDF") {
      toast.error("Please Select image or pdf File ", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });

      document.getElementById(id).value = "";
      return false;
    }
    if (file.size > 2048000) {
      toast.error("File must be less than 2 MB ", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });

      document.getElementById(id).value = "";
      return false;
    }
    return true;
  };

  render() {
    return (
      <div>
        {this.state.loading ? <BackDrop1 /> : null}

        <Modal
          open={this.state.open1}
          onClose={this.onCloseModal1}
          center
          classNames={{
            overlay: "customOverlay1",
            modal: "customModal2",
          }}
        >
          <h2 style={{ paddingLeft: "10px" }} className="white11">
            Customize Design{" "}
          </h2>
          <hr />
          <div className="card ">
            <div className="card-body">
              <form onSubmit={this.handleSubmit1}>
                <input
                  type="hidden"
                  name="user_id"
                  value={this.state.user_id}
                />
                <div className="form-group">
                  <label for="exampleInputEmail1 " className="white11">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    aria-describedby="emailHelp"
                    placeholder="Enter Name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  {this.validator.message("name", this.state.name, "required", {
                    className: "text-danger",
                  })}
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1 " className="white11">
                    Phone No
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Enter Phone Number"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  />
                  {this.validator.message(
                    "phone",
                    this.state.phone,
                    "required",
                    { className: "text-danger" }
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1 " className="white11">
                    Email Id (Optional)
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1 " className="white11">
                    Select City
                  </label>
                  <select
                    name="city_id"
                    id="city_id"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  >
                    <option value="" selected disabled>
                      -- Select City --
                    </option>
                    {this.state.city.map((object, i) => (
                      <option value={object.id} key={i}>
                        {object.city_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1 " className="white11">
                    Message (Optional)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="message"
                    name="message"
                    placeholder="Message"
                    value={this.state.message}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1 " className="white11">
                    Document Upload (Optional)
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="doc"
                    name="doc"
                    placeholder="Document"
                    value={this.state.doc}
                    onChange={(event) => {
                      this.imageValid(event);
                    }}
                  />
                </div>

                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </Modal>

        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          center
          classNames={{
            modal: "customModal",
          }}
        >
          <h2 style={{ paddingLeft: "10px" }}>Get Free Quotation </h2>
          <hr />
          <div className="card ">
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    aria-describedby="emailHelp"
                    placeholder="Enter Name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  {this.validator.message("name", this.state.name, "required", {
                    className: "text-danger",
                  })}
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1">Phone No</label>
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Enter Phone Number"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  />
                  {this.validator.message(
                    "phone",
                    this.state.phone,
                    "required",
                    { className: "text-danger" }
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1">Email Id (Optional)</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </Modal>

        <TopArea />

        <header className="header-area stricky">
          <div className="container">
            <section className="header-search">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="search-form pull-right">
                      <form onSubmit={this.search}>
                        <div className="search">
                          <input
                            type="search"
                            className="searchbackgroundcolor"
                            name="service_name"
                            value={this.state.name}
                            onChange={this.searchchange}
                            placeholder="Search Something"
                            style={{ color: "black" }}
                            required
                          />
                          <button type="submit">
                            <span className="icon fa fa-search"></span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="outer-box clearfix">
                  <div className="logo">
                    <Link to="/">
                      <img src="images/resources/logo.jpg" alt="Awesome Logo" />
                    </Link>
                  </div>

                  <div className="search-box-btn">
                    <div className="toggle-search">
                      <button style={{ color: "white11" }}>
                        <span className="icon fa fa-search"></span>
                      </button>
                    </div>
                  </div>

                  <div className="cart-btn">
                    <Link to="/cart">
                      <span
                        className="fa fa-heart carticon"
                        aria-hidden="true"
                      ></span>
                      {!checkAuth.isAuthenticated ? (
                        <span className="item-count">0</span>
                      ) : (
                        <span className="item-count">
                          {this.props.catdetails.cart_count}
                        </span>
                      )}
                    </Link>
                  </div>

                  <nav className="main-menu">
                    <div className="navbar-header">
                      <button
                        type="button"
                        className="navbar-toggle"
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                      >
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>
                    <div
                      className="navbar-collapse  collapse clearfix "
                      ref={this.exampleRef}
                    >
                      <ul className="navigation clearfix">
                        {/* <li className="current"><Link to="/" onClick={this.abc}><i class="fa fa-home" aria-hidden="true"></i></Link></li> */}

                        {this.state.cat.map((object, i) =>
                          i < 3 ? (
                            <li className="dropdown">
                              <Link
                                to={`shop?cat_id=${object.cat_id}`}
                                style={{ fontSize: "15px" }}
                              >
                                {object.cat_name}
                              </Link>
                              <ul>
                                {this.state.subcat
                                  .filter((gs) => gs.cat_id == object.id)
                                  .map((obj, i) => (
                                    <li>
                                      <Link
                                        to={`shop?subcat_id=${obj.subcat_id}`}
                                        onClick={this.abc}
                                      >
                                        {obj.subcat_name}
                                      </Link>
                                    </li>
                                  ))}
                                {!checkAuth.isAuthenticated ? (
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        this.props.history.push(`/login`);
                                      }}
                                    >
                                      Custom Design
                                    </a>
                                  </li>
                                ) : (
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        this.setState({ open1: true });
                                      }}
                                    >
                                      Custom Design
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </li>
                          ) : null
                        )}
                        <li className=" hide1">
                          <Link
                            to="/"
                            onClick={() => {
                              this.setState({ open1: true });
                            }}
                          >
                            Custom Design
                          </Link>
                        </li>

                        <li className="dropdown">
                          <a
                            href="javascript:void(0);"
                            style={{ fontSize: "15px" }}
                          >
                            Library
                          </a>
                          <ul>
                            {this.state.librarycat.map((object, i) => (
                              <li>
                                <Link
                                  to={`/library?id=${object.id}`}
                                  onClick={this.abc}
                                >
                                  {object.laibrary_cat_name} ({object.type})
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="dropdown">
                          <a
                            href="javascript:void(0);"
                            style={{ fontSize: "15px" }}
                          >
                            Contact Us
                          </a>
                          <ul>
                            <li>
                              <Link to="/contactus" onClick={this.abc}>
                                Contact Us{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/aboutus" onClick={this.abc}>
                                About US
                              </Link>
                            </li>
                            <li>
                              <Link to="/partner" onClick={this.abc}>
                                Become a partner
                              </Link>
                            </li>
                            <li>
                              <Link to="/carear" onClick={this.abc}>
                                Career
                              </Link>
                            </li>
                            <li>
                              <Link to="/privacy_policy" onClick={this.abc}>
                                Privacy Policy
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <a
                            href="javascript:void(0);"
                            style={{ fontSize: "15px" }}
                          >
                            More
                          </a>
                          <ul>
                            {!checkAuth.isAuthenticated ? (
                              <>
                                <li>
                                  <Link to="login" onClick={this.abc}>
                                    Login
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/service-provider"
                                    onClick={this.abc}
                                  >
                                    Service Providers
                                  </Link>
                                </li>
                              </>
                            ) : (
                              <>
                                <li>
                                  <Link to="/profile" onClick={this.abc}>
                                    My Account
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/service-provider"
                                    onClick={this.abc}
                                  >
                                    Service Providers
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/enquery" onClick={this.abc}>
                                    My Booking
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/customizedesign"
                                    onClick={this.abc}
                                  >
                                    Customize Booking
                                  </Link>
                                </li>
                              </>
                            )}
                            <li>
                              <Link to="/coupon" onClick={this.abc}>
                                Offer
                              </Link>
                            </li>
                            <li>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.arcreation.gng"
                                onClick={this.abc}
                                target="_blank"
                              >
                                Download App
                              </a>
                            </li>
                            {checkAuth.isAuthenticated ? (
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.signout();
                                  }}
                                >
                                  Logout
                                </a>
                              </li>
                            ) : null}
                          </ul>
                        </li>
                        {!checkAuth.isAuthenticated ? (
                          <li className=" hide1">
                            <Link to="/login" onClick={this.abc}>
                              Enquery
                            </Link>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    catdetails: state.addItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showCart: () => {
      dispatch(showCart());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
