import React,{useEffect} from 'react'
import { Link, withRouter } from 'react-router-dom'

export default function SuccessFull(props) {
    useEffect(() => {
        setTimeout(() => {
        props.history.push("/enquery");
            
        }, 5000);
      
      },[]);
  
        window.scrollTo(0, 0);
        return (
            <div>
                <section className="not-found-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="not-found-content text-center">

                                <img src="https://www.mahindrafirstchoiceservices.com/assests/images/bd/success-2.gif" alt="" />
    
    
                                </div>
                            </div>
                            <div className="col-md-12"><br/>
                                <h3><center>Your Has Been Booking successfull</center></h3>
                            {/* <Link to="/" className="btn btn-success">Home</Link> */}

                            </div>
                        </div>
                    </div>
                </section>
    
            </div>
       
    )
}
