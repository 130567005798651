import React, { Component } from 'react'

import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost, authget } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';
import { Link, withRouter } from 'react-router-dom'
import BackDrop1 from '../Loader/BackDrop1';

export default class Register extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0);
        this.state = {
            loading: false,
            name: '',
            email: '',
            phone: '',
            address: '',
            password: '',
            c_password: '',
            condition: '',
            msg:'',
            match:0,
            otp1:'',
            otp:'',
            sendotp:'SEND OTP',
            password_type:'password',
            password_type1:'password'
        }
        this.buttonotp = React.createRef()
        this.buttonregister = React.createRef()
        this.otpinput = React.createRef()
        this.validator = new SimpleReactValidator({
            messages: {
              in: 'Passwords need to match!'
            }
        })
    }
    otpSubmit =(e)=>{
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password,
            address: this.state.address,
            otp: this.state.otp,
        };
        if (this.validator.allValid()) {
            post('/api/phone_no_check_in_register', formdata)
                .then((data) => {
                    if(data.data.success == true){
                    this.setState({
                      otp1:data.data.otp,
                      sendotp:'RESEND OTP',
                      msg:''

                    })
                    var x = this.otpinput.current;
                    x.classList.remove('hide');
                    this.setState({ loading: false });
                  }else{
                    this.setState({
                        msg:data.data.msg,
                        // sendotp:'RESEND OTP'
                      })
                      this.setState({ loading: false });

                  }
            
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password,
            address: this.state.address,
        };
        if (this.validator.allValid()) {
            post('/api/register1', formdata)
                .then((data) => {
                    console.log(data.data)
                    toast.dark('Register Successful',
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    this.props.history.push('/login');
                    this.setState({ loading: false });
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
        if(e.target.name == 'otp'){
            var y = this.buttonregister.current; 
            var z = this.buttonotp.current; 

      

            if(e.target.value.toString().length>=6){
               
               
                if(Number(e.target.value) == Number(this.state.otp1))
                {
                   
                    z.classList.add('hide');
                   
                    y.classList.add('show');
                    this.setState({
                        msg:''
                   
                    })
                }else{
                   
                    y.classList.remove('show');
                    this.setState({
                        msg:'OTP Did Not Match'
                    })
                }
            }else{
                y.classList.remove('show');
            }
        }
    }
    tarm = e => {
        if (this.state.condition == '') {
            this.setState({
                condition: 1
            })
        } else {
            this.setState({
                condition: ''
            })

        }

    }
    UNSAFE_componentWillMount() {
        if (checkAuth.isAuthenticated)
            this.props.history.push('/')
    }
    ll=()=>{
        if(this.state.password_type == 'password'){
                this.setState({password_type:"text"})
        }else{
            this.setState({password_type:"password"})
        }
    }
    kk=()=>{
        if(this.state.password_type1 == 'password'){
                this.setState({password_type1:"text"})
        }else{
            this.setState({password_type1:"password"})
        }
    }

    render() {
        return (
            <div>
                {this.state.loading?<BackDrop1/>:null}
                <section className="account-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                <div className="form register">
                                    <div className="sec-title-two">
                                        <h3>Register Here</h3>
                                    </div>
                                    <form >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="field-label">Name</div>
                                                <div className="field-input">
                                                    <input type="text" className="form-control" name="name" id="name" placeholder="Enter Name" onChange={this.handleChange} value={this.state.name} />
                                                    {this.validator.message('name', this.state.name, 'required', { className: 'text-danger' })}

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="field-label">Email Address</div>
                                                <div className="field-input">
                                                    <input type="text" className="form-control" name="email" id="email" placeholder="Email ID" onChange={this.handleChange} value={this.state.email} style={{ "height": "43px" }} />
                                                    {this.validator.message('email', this.state.email, 'required', { className: 'text-danger' })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="field-label">Password</div>
                                                <div className="field-input">
                                                    <input type={`${this.state.password_type}`} className="form-control" id="password" name="password" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" onChange={this.handleChange} value={this.state.password} style={{ "height": "43px" }} />
                                                    <div className="icon-holder">
                                                    {this.state.password_type == 'password'?
                                                            <i className="fas fa-eye-slash" onClick={this.ll} style={{cursor :"pointer"}}></i>
                                                            :
                                                            <i className="fas fa-eye" onClick={this.ll} style={{cursor :"pointer"}}></i>
                                                        }
                                                    </div>
                                                    {this.validator.message('password', this.state.password, 'required|min:8|max:120', { className: 'text-danger' })}


                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="field-label">Confirm Password</div>
                                                <div className="field-input">
                                                    <input className="form-control" type={`${this.state.password_type1}`} name="c_password" id="c_password" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" onChange={this.handleChange} value={this.state.c_password} style={{ "height": "43px" }} />
                                                    <div className="icon-holder">
                                                    {this.state.password_type1 == 'password'?
                                                            <i className="fas fa-eye-slash" onClick={this.kk} style={{cursor :"pointer"}}></i>
                                                            :
                                                            <i className="fas fa-eye" onClick={this.kk} style={{cursor :"pointer"}}></i>
                                                        }
                                                    </div>
                                                    {this.validator.message('c_password', this.state.c_password, `required|in:${this.state.password}`,{ className: 'text-danger' }, {messages: {in: 'Passwords need to match!'}})}


                                                </div>
                                            </div>
                                        </div> <br>
                                        </br>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="field-label">Phone Number</div>
                                                <div className="field-input">
                                                    <input type="text" className="form-control" name="phone" id="phone" placeholder="Phone Number" style={{ "height": "43px" }} onChange={this.handleChange} value={this.state.phone} />
                                                    {this.validator.message('phone', this.state.phone, 'required', { className: 'text-danger' })}

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="field-label">Address</div>
                                                <div className="field-input">
                                                    <input type="text" name="address" id="address" className="form-control" placeholder="Address" onChange={this.handleChange} value={this.state.address} />
                                                    {this.validator.message('address', this.state.address, 'required', { className: 'text-danger' })}
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12 hide"  ref={this.otpinput}>
                                                <div className="field-label">OTP</div>
                                                <div className="field-input">
                                                    <input type="number" name="otp" id="otp" className="form-control" placeholder="OTP" onChange={this.handleChange} value={this.state.otp} style={{ "height": "43px" }}/>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="term-condition">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox" name="condition" onClick={this.tarm} />
                                                            <span>I agree the term’s & conditions</span>
                                                        </label>
                                                        {this.validator.message('condition', this.state.condition, 'required', { className: 'text-danger' })}
                                                        <div style={{color:"red"}}>{this.state.msg}</div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <button className="thm-btn bg-cl-1" type="submit" onClick={this.otpSubmit} ref={this.buttonotp} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : this.state.sendotp}</button>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12 hide" ref={this.buttonregister} >
                                                <button className="thm-btn bg-cl-1" type="submit" onClick={this.handleSubmit}   disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Register Here'}</button>
                                            </div>
                                            <Link to="login" className="forgot-password pull-left" style={{color:"green "}}>
                                                   I Already Have an Account
                                                </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            </div>
                        </div>
                    </div>
                </section>
            
            </div>
        )
    }
}
