import React, {
  Component,
  Suspense,
  lazy,
  useEffect,
  createContext,
  useReducer,
  useContext,
} from "react";
import "./App.css";
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  BrowserRouter as Router,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { checkAuth } from "./utils/auth";
import HomeContainer from "./container/HomeContainer";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Shop from "./components/Shop/Shop";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Page_404 from "./components/Page_404/Page_404";
import ProjectDetails from "./components/ProjectDetails/ProjectDetails";
import CompleteProjectDetails from "./components/CompleteProject/CompleteProjectDetails";
import Checkout from "./components/Checkout/Checkout";
import AboutUs from "./components/Information/AboutUs";
import ContactUs from "./components/Information/ContactUs";
import Faq from "./components/Information/Faq";
import Category from "./components/Home/Category";
import Home2 from "./components/Home2";
import Carear from "./components/Information/Carear";
import Partner from "./components/Information/Partner";
import Privacy_policy from "./components/Information/Privacy_policy";
import Coupon from "./components/Home/Coupon";
import ForgetPassword from "./components/Auth/ForgetPassword";
import Profile from "./components/Account/Profile";
import Shop1 from "./components/Shop/Shop1";
import Library from "./components/Shop/Library";
import Order from "./components/Order/Order";
import SuccessFull from "./components/Checkout/SuccessFull";
import Cart from "./components/Cart/Cart";
import CustomizeDesign from "./components/Order/CustomizeDesign";
import Plumber from "./components/Plumber/Plumber";
const Routing = (props) => {
  const history = useHistory();
  return (
    <>
      <Header {...props} />
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={0} classNames="fade">
              <Switch>
                <Route exact path="/" component={HomeContainer}></Route>
                <Route exact path="/phq" component={Home2}></Route>
                {/* <Route exact  path="/shop" component={Shop}></Route> */}
                <Route exact path="/shop" component={Shop1}></Route>
                <Route exact path="/login" component={Login}></Route>
                <Route
                  exact
                  path="/forgetpassword"
                  component={ForgetPassword}
                ></Route>
                <Route exact path="/register" component={Register}></Route>
                <Route
                  exact
                  path="/projectdetails"
                  component={ProjectDetails}
                ></Route>
                <Route
                  exact
                  path="/completeprojectdetails"
                  component={CompleteProjectDetails}
                ></Route>
                <PrivateRoute
                  exact
                  path="/checkout"
                  component={Checkout}
                ></PrivateRoute>
                <Route exact path="/aboutus" component={AboutUs}></Route>
                <Route exact path="/contactus" component={ContactUs}></Route>
                <Route exact path="/faq" component={Faq}></Route>
                <Route exact path="/carear" component={Carear}></Route>
                <Route exact path="/partner" component={Partner}></Route>
                <Route
                  exact
                  path="/privacy_policy"
                  component={Privacy_policy}
                ></Route>
                <PrivateRoute
                  exact
                  path="/profile"
                  component={Profile}
                ></PrivateRoute>
                <Route exact path="/coupon" component={Coupon}></Route>
                <Route exact path="/shop" component={Shop1}></Route>
                <PrivateRoute
                  exact
                  path="/library"
                  component={Library}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/enquery"
                  component={Order}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/successfull"
                  component={SuccessFull}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/cart"
                  component={Cart}
                ></PrivateRoute>
                <Route
                  exact
                  path="/customizedesign"
                  component={CustomizeDesign}
                ></Route>
                <Route
                  exact
                  path="/service-provider"
                  component={Plumber}
                ></Route>

                <Route component={Page_404}></Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
      <Footer />
    </>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Routing {...this.props} />
      </div>
    );
  }
}

export default withRouter(App);
