import React ,{ Component,Suspense,lazy}from 'react'
import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
const Home=lazy(()=>import('../components/Home'))

export default function HomeContainer() {
    return (
        <div>
           
            <Suspense   fallback={<div>...Loading</div>}> 
              <Home/>  
            </Suspense> 
        </div>
    )
}
