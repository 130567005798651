import {
    Add_To_Cart,
    Remove_To_Cart,
    Update_Profile,
    GET_WALLET,
    SHOW_CART,
} from '../constants'
import { authput, authget, post, authformpost, authdelete, get } from '../../utils/service'




export function addItems(state = [], action) {
    switch (action.type) {
        case Add_To_Cart:
            console.log(action)
            return action.payload
            // return[
            //     ...state,
            //     {cardData:action.payload}
            // ]
        case SHOW_CART:
            console.log(action)
            return action.payload
            // return[
            //     ...state,
            //     {cardData:action.payload}
            // ]


        default:
           

            return state; 
    }

}

